// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  //EDB_API: 'API_BASE_URL',
  //EDB_OAUTH_CLIENT: 'OAUTH_CLIENT_KEY',

  //LOCAL
  //EDB_OAUTH_CLIENT: 'ZWRiLXdlYjplZGItd2ViMjAyMQ==',
  //EDB_API: 'http://localhost:8082',

  //TESTE
  EDB_OAUTH_CLIENT: 'ZWRiLXdlYjojZWRiIy0yMDIxLUA=',
  //EDB_API: 'http://172.31.4.91:8082',
  //Treino
  //EDB_API: 'https://edbtreino.pgr.gov.mz/api',
  //Produção
  EDB_API: 'https://declaracaodebens.pgr.gov.mz/api',
  EDB_VERSION: '03.06.00',

  //'MY_APP_API_URL',
  //EDB_API: window["env"]["apiUrl"] || "default",
  //EDB_DEBUG: window["env"]["debug"] || false
};
