import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SpinnerService } from "app/shared/spinner/spinner.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";


@Injectable()
export class SpinnerInterceptor implements HttpInterceptor{

    constructor(private spinnerService: SpinnerService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinnerService.requestSarted();
        return this.handler(next, request);
    }

    handler(next, request) {
        return next.handle(request)
        .pipe(
            tap(
                (event) => {
                    if(event instanceof HttpResponse) {
                        this.spinnerService.requestEnded();
                    }
                },
                (error: HttpErrorResponse) => {
                    this.spinnerService.requestEnded();
                    throw error;
                },
                () => {
                    this.spinnerService.requestEnded();
                }
            )
        )
    }
}