import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DeclarationService } from 'app/core/services/declaration.service';
import { InstitutionService } from 'app/core/services/institution.service';
import { PersonService } from 'app/core/services/person.service';
import { SecurityService } from 'app/security/services/security.service';
import { FormValidations } from 'app/shared/fom-validations/form-validations';
import { UploadDownloadService } from 'app/shared/upload-download/upload-download.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-hrdeclaration-report',
  templateUrl: './hrdeclaration-report.component.html',
  styleUrls: ['./hrdeclaration-report.component.css']
})
export class HRDeclarationReportComponent implements OnInit {

  form!: FormGroup;
  declarationTypeDropdownSettings = {};
  declarationTypeDropdownList = [];
  declarationTypeSelectedItems = [];
  currentDate: Date;

  constructor(private router: Router, private formBuilder: FormBuilder, private personService: PersonService, private declarationService: DeclarationService, private securityService: SecurityService, private uploadDownloadService: UploadDownloadService) {
    this.currentDate = new Date();
  }

  ngOnInit(): void {
  }
  private createDeclarationSearchForm() {
    this.form = this.formBuilder.group({
      processNumber: [null, [FormValidations.processNumber]],
      nuit: [null, [FormValidations.nuit]],
      name: [null],
      declarantRole: [null],
      declarantInstitution: ["qualquer"],
      minDate: [""],
      maxDate: [""],
      declarationType: [null],
      verificationStatus: [null],
      lateDeclaration:["qualquer"],
      sectors: [null],
      fileType: ["PDF", Validators.required],
    }, {validator: FormValidations.greaterOrEqualThanMinDate('minDate', 'maxDate')});
  }

  public clearSearchFields(){
    this.form.patchValue({
        processNumber: null,
        nuit: null,
        name: null,
        declarantRole: null,
        declarantInstitution: "qualquer",
        minDate: "",
        maxDate: "",
        declarationType: null,
        verificationStatus: null,
        lateDeclaration: "qualquer",
        sectors: null,
        fileType: "PDF"
    });
  }

  private prepareMultiselect(){
    this.declarationTypeDropdownList = [
      {"id":1,"itemName":"Inicial"},
      {"id":2,"itemName":"Actualização"},
      {"id":3,"itemName":"Cessação"}
    ];

    this.declarationTypeDropdownSettings = { 
      singleSelection: false, 
      text:"Selecione o(s) tipo(s) de declaração",
      selectAllText:'Selecionar todos tipos',
      unSelectAllText:'Desselecionar todos tipos',
      filterUnSelectAllText:'Desselecionar todos tipos encontrados',
      classes:"myclass custom-class",
      noDataLabel: "Sem tipos de declaração disponíveis!"
    };

  }

  onItemSelect(item: any) {
    
  }

  onSelectAll(items: any) {
    
  }

 OnItemDeSelect(item:any){
    
  }
  onDeSelectAll(items: any){
      
  }

  resetMinDate(minDate){
    if(minDate == null) 
      this.form.patchValue({
        minDate: ""
      });
  }

  resetMaxDate(maxDate){
    if(maxDate == null) 
      this.form.patchValue({
        maxDate: ""
      });
  }

  createJSONofDeclarationFilters():any{
    let minDate, maxDate;
    let selectedInstitution = this.form.value.declarantInstitution == "qualquer" ? null : this.form.value.declarantInstitution;

    if(this.form.value.lateDeclaration == "qualquer")
      this.form.value.lateDeclaration = null;

    if(this.declarationTypeSelectedItems != null){
      if(this.declarationTypeDropdownList.length == this.declarationTypeSelectedItems.length || this.declarationTypeSelectedItems.length == 0)
        this.form.value.declarationType = null;
      else{
        this.form.value.declarationType = [];
        this.declarationTypeSelectedItems.forEach(element => {
          this.form.value.declarationType.push(element.itemName);        
        });  
      }
    }
        
    if(this.form.value.minDate.toString().trim() == "")
      minDate = null;
    else
      minDate = this.formatDate(this.form.value.minDate);
    if(this.form.value.maxDate.toString().trim() == "")
      maxDate = null;
    else
      maxDate = this.formatDate(this.form.value.maxDate);

    return {
      processNumber: this.form.value.processNumber,
      declarantNUIT: this.form.value.nuit,
      declarantName: this.form.value.name,
      declarantRole: this.form.value.declarantRole,
      declarantInstitution: selectedInstitution,
      declarationMinDate: minDate,
      declarationMaxDate: maxDate,
      declarationType: this.form.value.declarationType,
      verificationStatus: this.form.value.verificationStatus,
      lateDeclaration: this.form.value.lateDeclaration,
      sectors: this.form.value.sectors,
      fileType: this.form.value.fileType
    };
  }

  formatDate(date): string {
    let month = date.month < 10 ? '0' + date.month : date.month;
    let day = date.day < 10 ? '0' + date.day : date.day;
    return date.year + '-' + month + '-' + day;
  }

  submit(){
    this.removeInvalidDeclarationDateRangeError();    
    if (this.form.valid) {
      const formData = this.createJSONofDeclarationFilters();
      let fileExtension = formData.fileType.toUpperCase() == "PDF" ? ".pdf" : ".xlsx";
      
      this.declarationService.generateDeclarationReport(formData).subscribe(
        (response: any) => {
          this.uploadDownloadService.handleFile(response, 'relatorio_de_declaracoes_' + this.getFormatedCurrentDateAndTime() + fileExtension);
        },
        (error) => {
          let errorMsgString = error?.statusText ? error?.statusText : error.message;
          Swal.fire({
            icon: "error",
            title: "Erro",
            html: errorMsgString,
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-danger"
            }
          })
        }
      );
    } else {
      if(this.form.errors !== null){
        if("greaterOrEqualThanMinDate" in this.form.errors || "lessThanOrEqualThanMaxDate" in this.form.errors){
          this.setInvalidDeclarationDateRange();
        }
      }
      this.ignoreEmptyDate();
      if(this.form.valid){
        this.submit();
      } else {
        this.validateAllFormFields(this.form);
        Swal.fire({
          icon: "warning",
          title: "Preencha o formulário, correctamente!",
          text: "Veja os erros, por baixo dos campos",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary"
          }
        });
      }      
    }
  }

  validateAllFormFields(formGroup: FormGroup) {         
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);             
      if (control instanceof FormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        
        this.validateAllFormFields(control);            
      }
    });
  }
  
  validateDeclarationDateRange(){
    let minControl = this.form.controls['minDate'];
    let maxControl = this.form.controls['maxDate'];
    let min = new Date(`${minControl.value.year}-${minControl.value.month}-${minControl.value.day}`);     
    let max = new Date(`${maxControl.value.year}-${maxControl.value.month}-${maxControl.value.day}`);     
    
    if (min.getTime() > max.getTime()) {
      this.setInvalidDeclarationDateRange();
    } else {
      this.removeInvalidDeclarationDateRangeError();
    }
  }

  setInvalidDeclarationDateRange(){
    this.form.controls['minDate'].setErrors({ 'lessThanOrEqualThanMaxDate': 'Data mínima deve ser igual ou menor do que data máxima'});
    this.form.controls['maxDate'].setErrors({ 'greaterOrEqualThanMinDate': 'Data máxima deve ser igual ou maior do que data mínima'});
  }

  removeInvalidDeclarationDateRangeError(){
    delete this.form.controls['minDate'].errors?.lessThanOrEqualThanMaxDate;
    delete this.form.controls['maxDate'].errors?.greaterOrEqualThanMinDate;
    if(this.form.controls['minDate'].errors !== null){
      if(Object.keys(this.form.controls['minDate'].errors).length == 0){
        this.form.controls['minDate'].setErrors(null);
      }
    }
    if(this.form.controls['maxDate'].errors !== null){
      if(Object.keys(this.form.controls['maxDate'].errors).length == 0){
        this.form.controls['maxDate'].setErrors(null);
      }
    }
  }

  private ignoreEmptyDate(){
    if(this.form.controls['minDate'].errors !== null){
      if("ngbDate" in this.form.controls['minDate'].errors){
        if(this.form.controls['minDate'].value.trim() == ""){
          delete this.form.controls['minDate'].errors.ngbDate;
        }
      }
      if(Object.keys(this.form.controls['minDate'].errors).length == 0){
        this.form.controls['minDate'].setErrors(null);
      }
    }

    if(this.form.controls['maxDate'].errors !== null){
      if("ngbDate" in this.form.controls['maxDate'].errors){
        if(this.form.controls['maxDate'].value.trim() == ""){
          delete this.form.controls['maxDate'].errors.ngbDate;
        }
      }
      if(Object.keys(this.form.controls['maxDate'].errors).length == 0){
        this.form.controls['maxDate'].setErrors(null);
      }
    }
  }

  getFormatedCurrentDateAndTime(){
    let d = new Date();
    return d.getDate()+"_"+(d.getMonth()+1)+"_"+d.getFullYear()+"_"+d.getHours()+"_"+d.getMinutes();
  }

}
