import {Injectable} from '@angular/core';
import {ClientService} from '../../security/services/client.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Declarant} from '../models/declarant.model';
import {DeclarantsRequest} from '../models/declarants-request.model';
import { CheckAssignedValue } from 'app/shared/custom-validator/check-assigned-value';

@Injectable({
  providedIn: 'root'
})
export class DeclarantService {
  private readonly personService = 'person/';
  private readonly declarantService = 'declarants/';
  private readonly institutionService = 'institution/';
  private readonly eligibleDeclarantService = 'eligibleDeclarant/';
  private url;

  constructor(
    private http: HttpClient,
    private clientService: ClientService) {
  }

  /* public getDeclarantByNUIT(desc): Observable<Declarant[]> {
    this.url = this.clientService.urlIPWS(`${this.declarantService}search/` + desc)
    return this.http.get<Declarant[]>(this.url);
  } */

  public getDeclarantByNUIT(nuit): Observable<Declarant[]> {
    this.url = this.clientService.urlIPWS(`${this.declarantService}fetch-by-nuit/` + nuit)
    return this.http.get<Declarant[]>(this.url);
  }

  public getDeclarantById(nuit): Observable<Declarant> {
    this.url = this.clientService.urlIPWS(`${this.declarantService}fetch/` + nuit)
    return this.http.get<Declarant>(this.url);
  }
  
  public getProcessNumber(declaranID): Observable<JSON> {
    this.url = this.clientService.urlIPWS(`${this.declarantService}getProcessNumber?declarantID=` + declaranID)
    return this.http.get<JSON>(this.url);
  }

  public getActiveDeclarantByPersonNuitInstitutionSector(nuit): Observable<Declarant> {
    this.url = this.clientService.urlIPWS(`${this.declarantService}find-active-by-nuit-institution-sector/` + nuit)
    return this.http.get<Declarant>(this.url);
  }
  
  public getDeclarantsById(id): Observable<Declarant[]> {
    this.url = this.clientService.urlIPWS(`${this.declarantService}find/` + id)
    return this.http.get<Declarant[]>(this.url);
  }

  public saveOnlyDeclarantData(declarant: Declarant): Observable<Declarant> {
    if(!CheckAssignedValue.isEmpty(declarant?.id)) {
      return this.updateOnlyDeclarantData(declarant?.id, declarant);
    } else {
      return this.createOnlyDeclarantData(declarant);
    }
  }

  public createOnlyDeclarantData(declarantRequest: Declarant): Observable<Declarant> {
    this.url = this.clientService.urlIPWS(`${this.declarantService}create-only-declarant/`)
    return this.http.post<Declarant>(this.url, declarantRequest);
  }

  public updateOnlyDeclarantData(declarantId: number, declarantRequest: Declarant): Observable<Declarant> {
    this.url = this.clientService.urlIPWS(`${this.declarantService}update-only-declarant/${declarantId}`)
    return this.http.put<Declarant>(this.url, declarantRequest);
  }

  public createDeclarant(declarantRequest: DeclarantsRequest): Observable<DeclarantsRequest> {
    this.url = this.clientService.urlIPWS(`${this.declarantService}confirm/`)
    return this.http.post<DeclarantsRequest>(this.url, declarantRequest);
  }

  inactivateDeclarants(id) {
    this.url = this.clientService.urlIPWS(`${this.declarantService}inactivate/` + id)
    return this.http.put<Declarant>(this.url, id);
  }

  listByInstitution() {
    this.url = this.clientService.urlIPWS(`${this.declarantService}search`)
    return this.http.get<Declarant[]>(this.url);
  }

  rejectDeclarant(nuit) {
    this.url = this.clientService.urlIPWS(`${this.declarantService}reject/` + nuit)
    return this.http.put<Declarant>(this.url, nuit);
  }

  SendCredentials(nuit) {
    this.url = this.clientService.urlIPWS(`${this.declarantService}sendsms/` + nuit)
    return this.http.post<Declarant>(this.url, nuit);
  }

  updateDeclarant(declarant: Declarant): Observable<Declarant> {
    this.url = this.clientService.urlIPWS(`${this.declarantService}update`)
    return this.http.put<Declarant>(this.url, declarant);
  }

  getInstitutionIDByNUIT(nuit: string){
    this.url = this.clientService.urlIPWS(`${this.institutionService}get-institution-id-by-nuit/`+nuit)
    return this.http.get(this.url);
  }

  getHRDeclarantReportData(){
    this.url = this.clientService.urlIPWS(`${this.eligibleDeclarantService}get-hr-declarant-report-data`)
    return this.http.get(this.url);
  }

  public generateDeclarantReport(fileType: string){
    this.url = this.clientService.urlIPWS(`${this.eligibleDeclarantService}generate-hr-declarant-report?fileType=${fileType}`)
    return this.http.get(this.url,{responseType: 'blob' as 'json'});
  }
}