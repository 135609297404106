import { Component, OnInit } from '@angular/core';
import { HRDeclarantReport } from 'app/core/models/hrdeclarant-report.model';
import { DeclarantService } from 'app/core/services/declarant.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { UploadDownloadService } from 'app/shared/upload-download/upload-download.service';

@Component({
  selector: 'app-hrdeclarant-report',
  templateUrl: './hrdeclarant-report.component.html',
  styleUrls: ['./hrdeclarant-report.component.css']
})
export class HRDeclarantReportComponent implements OnInit {

  data: HRDeclarantReport = null;
  form!: FormGroup;

  constructor(private declarantService: DeclarantService, private formBuilder: FormBuilder, private uploadDownloadService: UploadDownloadService) { }

  async ngOnInit(): Promise<void> {
    this.createDeclarationSearchForm();
    await this.getHRDeclarantReportData();
  }

  private async getHRDeclarantReportData(){
    await this.declarantService.getHRDeclarantReportData().subscribe(
      response => {
        this.data = response as HRDeclarantReport;
      },
      error => {
        Swal.fire(error.error.prettyMessage, error.error.prettyMessage, 'error')
      }
    );
  }

  private createDeclarationSearchForm() {
    this.form = this.formBuilder.group({
      fileType: ["PDF", Validators.required],
    });
  }

  createJSONOfForm():any{
    return {
      fileType: this.form.value.fileType
    };
  }

  downloadReport(formData){
    let fileExtension = formData.fileType.toUpperCase() == "PDF" ? ".pdf" : ".xlsx";
    
    this.declarantService.generateDeclarantReport(fileExtension).subscribe(
      (response: any) => {
        this.uploadDownloadService.handleFile(response, 'relatorio_de_declarantes_' + this.getFormatedCurrentDateAndTime() + fileExtension);
      },
      (error) => {
        let errorMsgString = error?.statusText ? error?.statusText : error.message;
        Swal.fire({
          icon: "error",
          title: "Indisponibilidade para criação de ficheiro de relatório.",
          //html: errorMsgString,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger"
          }
        })
      }
    );
  }

  getFormatedCurrentDateAndTime(){
    let d = new Date();
    return d.getDate()+"_"+(d.getMonth()+1)+"_"+d.getFullYear()+"_"+d.getHours()+"_"+d.getMinutes();
  }

}