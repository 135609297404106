import {Component, OnInit} from '@angular/core';
import {DeclarantService} from '../../../services/declarant.service';
import {Router} from '@angular/router';
import { SecurityService } from 'app/security/services/security.service';
import { UserModel } from 'app/core/models/user.model';

@Component({
  selector: 'app-search-declarant',
  templateUrl: './search-declarant.component.html',
  styleUrls: ['./search-declarant.component.css']
})
export class SearchDeclarantComponent implements OnInit {
  desc = '';
  public user: UserModel;

  constructor(private declarantService: DeclarantService,
              private router: Router, private securityService: SecurityService) {
  }

  ngOnInit(): void {
    this.user = this.securityService.user
  }

  create() {
    this.router.navigate(['rh/declarante/create-declarant'])
  }

  search(desc) {
    if(this.user.accessProfileCodes.includes('RH')) {
      this.router.navigate(['rh/declarante/list-declarant/', desc])
    } else if(this.user.accessProfileCodes.includes('CRV')) {
      this.router.navigate(['crv/declarante/list-declarant/', desc])
    }
  }
}