import { Component, OnInit, ViewChild } from '@angular/core';
import { UploadlistService } from '../../../services/uploadlist.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import * as XLSX from 'xlsx';
import { EligibleDeclarant } from '../models/EligibleDeclarant';
import { Router } from '@angular/router';
import { CheckAssignedValue } from 'app/shared/custom-validator/check-assigned-value';
import { ExcelEligibleDeclarantValidation } from 'app/core/models/excel-eligible-declarant-validation.model';
import { FormValidations } from 'app/shared/fom-validations/form-validations';
import { AppState, GenericComponent } from 'app/shared/implementations/GenericComponent';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { EligibleUploadListCollectErrorsValidationService } from './eligible-upload-list-collect-errors-validation.service';


@Component({
  selector: 'app-upload-list',
  templateUrl: './upload-list.component.html',
  styleUrls: ['./upload-list.component.css']
})
export class UploadListComponent extends GenericComponent implements OnInit {

  @ViewChild('inputEligibleDeclarantExcelFile') inputEligibleDeclarantExcelFile;
  eligibleDeclarants: EligibleDeclarant[] = [];
  private eligibleDeclarantNuits : Array<String> = [];
  private errorsExcelEligible: ExcelEligibleDeclarantValidation[] = [];



  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  uploadedFile: any;

  private filling_instructions_upload = [
    { code: 1, desc: 'Indicar o ficheiro a ser carregado' },
    { code: 2, desc: 'Carregar ficheiro em formato (.XLSX)' },
    { code: 3, desc: 'Verificar se os dados estão devidamente preenchidos antes do carregar do ficheiro' },
  ]


  constructor(private uploadService: UploadlistService,
    private spinner: NgxSpinnerService,
    private router: Router,
    location: Location,
    store: Store<AppState>,
    private eligibleUploadListCollectErrorsValidationService : EligibleUploadListCollectErrorsValidationService) {
    super(location, store);
  }

  ngOnInit(): void {
  }

  selectFiles(event) {
    this.selectedFiles = event.target.files;
    this.uploadedFile = event.target;
  }

  upload() {
    this.spinner.show();
    setTimeout(() => {

      this.spinner.hide();
    }, 500);
    this.currentFile = this.selectedFiles.item(0);
    this.uploadService.upload(this.currentFile);
    this.alertWithSuccess();
  }

  onFileChange() {

    const target: DataTransfer = <DataTransfer>(this.uploadedFile);

    this.errorsExcelEligible = this.eligibleUploadListCollectErrorsValidationService.collectEligibleDeclarantErrorsValidations(null, target, this.eligibleDeclarantNuits);
    this.executeValidations();

    /* if (target.files.length > 1) {
      let errorExcelEligible = new ExcelEligibleDeclarantValidation;
      errorExcelEligible.error = 'Não é permitido carregar mais de um ficheiro!';
      errorExcelEligible.position = '';
      this.errorsExcelEligible.push(errorExcelEligible);
    } */

    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {

      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });


      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];


      const data = XLSX.utils.sheet_to_json(ws, { raw: false, dateNF: 'yyyy-mm-dd' });



      this.composeEligibleDeclarantPayload(data);
      this.errorsExcelEligible = this.eligibleUploadListCollectErrorsValidationService.collectEligibleDeclarantErrorsValidations(this.eligibleDeclarants, target, this.eligibleDeclarantNuits);
      this.executeValidations();

      this.submit(this.eligibleDeclarants);
    };
  }

  private composeEligibleDeclarantPayload(data) {
    for (var val of data) {
      //this.eligibleDeclarants.push(<EligibleDeclarant>JSON.parse(JSON.stringify(val)));
      this.eligibleDeclarants.push(new EligibleDeclarant(JSON.stringify(val)));
      this.eligibleDeclarantNuits.push(val?.nuit)
    }
  }


  private submit(eligibleDeclarants) {
    // return;
    this.uploadService.createEligibleDeclarantFromFile(eligibleDeclarants)
      .subscribe(
        (response) => {
          Swal.fire({
            icon: "success",
            title: "O ficheiro foi submitido com sucesso'",
            confirmButtonText: 'Fechar',
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary"
            }
          })

        },
        (error) => {

          let errorMsg = "Ocorreu um erro, por favor volte a tentar mais tarde!";

          if (error?.error?.message) {
            errorMsg = error?.error?.message;
          } else if (error?.error?.prettyMessage) {
            errorMsg = error?.error?.prettyMessage
          }

          Swal.fire({
            icon: "error",
            title: "Erro",
            text: errorMsg,
            confirmButtonText: 'Fechar',
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary"
            }
          })
        }).add(() => {
          this.clearFormData();
        });

  }

  private executeValidations() {
    if (this.errorsExcelEligible.length != 0) {
      this.throwErrorsValidations();
      throw new Error("Erro de validação no carregamento do ficheiro");
      ;
    }
  }


  private throwErrorsValidations() {

    if (this.errorsExcelEligible.length != 0) {
      let errorMessageValidation: string = '';

      for (var errorExcelEligible of this.errorsExcelEligible) {
        let underscore = errorExcelEligible.position ? ' - ' : '';
        errorMessageValidation = errorMessageValidation + errorExcelEligible.error + underscore + errorExcelEligible.position + '.</br>';
      }

      Swal.fire({
        icon: "error",
        title: "Erro de Validação",
        html: errorMessageValidation,
        confirmButtonText: 'Fechar',
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary"
        }
      });
      
      this.clearFormData();
      
    }

  }

  private clearFormData() {
    this.eligibleDeclarants = [];
    this.eligibleDeclarantNuits = [];
    this.inputEligibleDeclarantExcelFile.nativeElement.value = '';
    this.errorsExcelEligible = [];
  }

  alertWithSuccess() {
    Swal.fire('O carregamento do Ficheiro', 'O ficheiro foi submitido com sucesso', 'success')
  }

  listEligibleDeclarants() {
    this.router.navigate(['/rh/declarante/listEligibleDeclarants'])
  }
}
