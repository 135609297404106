import {Injectable} from '@angular/core';
import {ClientService} from './client.service';

@Injectable({
  providedIn: 'root'
})
export class BlackListService {

  private blackListRoutes = new Map();
  private POST = 'POST';
  private GET = 'GET';
  private PUT = 'PUT';

  constructor(private clientService: ClientService) {
    this.build();
  }

  build() {
    this.blackListRoutes.set(this.clientService.urlAuthWS('oauth/token'), this.POST);
  }

  isBlacklistRoutes(path, httpMethod) {
    const http = this.blackListRoutes.get(path);

    if (http && http === httpMethod) {
      return true;
    }
    return false;
  }
}
