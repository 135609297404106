import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-declarant',
  templateUrl: './declarant.component.html',
  styleUrls: ['./declarant.component.css']
})
export class DeclarantComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
