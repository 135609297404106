import { HttpHeaders } from '@angular/common/http';
import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DeclarationService } from 'app/core/services/declaration.service';
import { AppState } from 'app/shared/implementations/GenericComponent';
import { cssNumber } from 'jquery';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-search-for-declaration',
  templateUrl: './search-for-declaration.component.html',
  styleUrls: ['./search-for-declaration.component.css']
})
export class SearchForDeclarationComponent implements OnInit {

  form!: FormGroup;
  submitted: boolean;
  declarationDataResponse: any;
  secondPartRequestForm!: FormGroup;
  searchVisibility: boolean = false;
  secondPartRequest: boolean = false;
  searchParameterVisibillity: boolean = true;
  chosenDeclarationId : any;
  nuitVisibillity: boolean = true;
  mustName: boolean = true;
  mustSurname: boolean = true;

  constructor(
    store: Store<AppState>, private fb: FormBuilder, private declarationService: DeclarationService, private router: Router) {
  }

  ngOnInit(): void {
    this.createFormData();
    this.createRequestFormData();
  }

  mainPage(){
    this.router.navigate(['/pages/login'])
  }


  createFormData() {
    this.form = this.fb.group({
      nuit: [null],
      name: [null],
      surname: [null],
      declarations: this.fb.array([this.declarationForm()]),


    })
  }

  search(){

    let nuit = this.form.value.nuit;
    let names = this.form.value.name;



    if (nuit==null && names==null){

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Nenhum Parâmetro de Pesquisa foi Preenchido!',
        footer: '<a href>Pagina Inicial</a>',
      });

      return 
    }


    if (nuit==null){

      if(names==null){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Preencha o nome e o Sobrenome!',
          footer: '<a href>Pagina Inicial</a>',
        });
        return
      }
    }
    
    if (nuit!=null){

      names = 'none';

      if(nuit.length!=9){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'O NUIT deve ter 9 digitos!',
          footer: '<a href>Pagina Inicial</a>',
        });
        return
      }

  

    }else if(nuit == null){
      nuit = 'none';
    }else if (names != null){
      nuit = 'none';
    }

    this.submitted = true;

    this.declarationService.searchForDeclarationFirstPart(nuit, names)?.subscribe(
      response => {
        this.declarationDataResponse = response;
        this.setSearchData(response)
        this.searchVisibility = true;
      },
      error => {
        const errorPrettyMsg = error.error?.message ? error.error?.message : "O sistema encontra-se indisponível, por favor volte a tentar!";
        Swal.fire({
          icon: "error",
          title: "Indisponibilidade para busca de declarantes",
          text: errorPrettyMsg,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger"
          }
        });
      }

    );

    

  }

  declarationForm(){
    return this.fb.group(
      {
        adress:[null],
        careerDescription:[null],
        fullname:[null],
        functionStartDate:[null],
        idDoc:[null],
        maritalStatus:[null],
        roleDescription:[null],
        declarationId:[null],
      }
    )
  }

  createRequestFormData(){
    this.secondPartRequestForm = this.fb.group(
      {

        motivation: [null],
        nuit: [null],
        fullname: [null],
        phoneNumber: [null],
        email: [null],
        requestState: [null],
        declarationId: [null]
  
        }
    )
  }

  setSearchData(completeData){
    this.searchParameterVisibillity = false;
    this.form.patchValue({
      declarations: completeData?.declarations? completeData?.declarations : null
    })

    this.declarations.clear();

    if(completeData?.declarations.length == 0) {
      const declarations = this.declarationForm();
      this.declarations.push(declarations);
    } else {
      for (let index = 0; index < completeData?.declarations.length; index++) {
        const declarations = this.declarationForm();
        declarations.patchValue(completeData?.declarations[index]);
        this.declarations.push(declarations);
      }
    }

  }

  get declarations() {
    return this.form.get("declarations") as FormArray;
  }


  openSpecificDeclarationForm(gottenDeclaration){
    this.searchVisibility = false;
    this.chosenDeclarationId = gottenDeclaration.get("declarationId").value;
    this.secondPartRequest = true;
  }


  sendRequest(){

    this.secondPartRequestForm.patchValue({
      declarationId: this.chosenDeclarationId
    })
    // this.declarationService.makeTheRequest(this.secondPartRequestForm.value);

    this.declarationService.makeTheRequest(this.secondPartRequestForm.value).subscribe((response) => {
    }, (error) => {
      const errorPrettyMsg = error.error?.message ? error.error?.message : "O sistema encontra-se indisponível, por favor volte a tentar!";
        Swal.fire({
          icon: "error",
          title: "Indisponibilidade do Sistema", 
          text: "errorPrettyMsg",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger"}
          });

    },
    () => {
      Swal.fire('Solicitacao feita com sucesso ', 'Sucesso', 'success')
      this.router.navigate(['/pages/login']);
    })

    


  }

  goBackToSearchPage(){
    this.searchParameterVisibillity = true;
    this.searchVisibility = false;
    this.form.patchValue(
      {
        nuit: null,
        name: null,
        surname:null
      }
    )

  }

  eraseName(){
    this.form.patchValue({
      name: null
    })
  }

  eraseNUIT(){
    this.form.patchValue({
      nuit: null
    })
  }


 

}
