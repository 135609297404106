
export class CheckAssignedValue {
    
    public static isEmpty(object) {
        let noValues : Array<String> = [null, undefined, ''];
        let result : Boolean = false;

        if(noValues.includes(object)) {
            result = true;            
        }

        return result;
    }
}
