import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {SecurityService} from '../services/security.service';
import {ClientService} from '../services/client.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private securityService: SecurityService,
              private clientService: ClientService,
              private router: Router,
              private toastrService: ToastrService,
              private jwt: JwtHelperService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const accessToken = this.clientService.getAccessToken();
    if (accessToken && this.securityService.user != null) {
      if(this.securityService.user.userNeedsToChangePassword || this.securityService.user.userNeedsToChangePassword == null){
        this.router.navigate(['/administration/mudar-senha']);
      }else{
        if (!this.jwt.isTokenExpired(accessToken)) {
          return true;
        } else {
          this.clientService.resetAccessToken()
          this.router.navigate(['/pages/login']);
          this.toastrService.error('A sua sessão expirou! .....');
        }
      }
    } else {
      this.clientService.resetAccessToken();
      this.router.navigate(['/pages/login']);
      this.toastrService.error('Primeiro inicie sessão!');
    }
  }
}
