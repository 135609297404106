import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Transaction } from 'app/core/models/Transaction.model';
import { UserModel } from 'app/core/models/user.model';
import { PersonService } from 'app/core/services/person.service';
import { ClientService } from 'app/security/services/client.service';
import { SecurityService } from 'app/security/services/security.service';
import { environment } from 'environments/environment';
import Swal from 'sweetalert2';

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  collapse?: string;
  icontype: string;
  // icon: string;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [{
  path: '/home',
  title: 'Home',
  type: 'link',
  icontype: 'nc-icon nc-bank'
},
{
  path: '/pages',
  title: 'Pages',
  collapse: 'pages',
  type: 'sub',
  icontype: 'nc-icon nc-book-bookmark',
  children: [
    { path: 'login', title: 'Login Page', ab: 'LP' },
    { path: 'register', title: 'Register Page', ab: 'RP' },
    { path: 'lock', title: 'Lock Screen Page', ab: 'LSP' }
  ]
},
{
  path: '/administration',
  title: 'Administraçao',
  collapse: 'administration',
  type: 'sub',
  icontype: 'nc-icon nc-book-bookmark',
  children: [
    { path: 'users', title: 'Utilizadores', ab: 'U' },
    { path: 'mudarSenha', title: 'Mudar senha', ab: 'MS' }
  ]
}
];

@Component({
  moduleId: module.id,
  selector: 'sidebar-cmp',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent {
  private webAppVersion;
  public user: UserModel;
  public transactions: Array<Transaction>;
  public menuItems: any[];
  private personDetails;
  // private personDetails : { organDescription: String, crv: String};

  constructor(private clientService: ClientService, private securityService: SecurityService, private personService: PersonService, private router: Router) { }

  isNotMobileMenu() {
    if (window.outerWidth > 991) {
      return false;
    }
    return true;
  }

  ngOnInit() {

    this.personDetails = null;
    this.user = this.securityService.user
    this.transactions = this.securityService.transactions;
    if(this.user?.username != null && this.user?.username != undefined && this.user?.username != "") {
      if(this.user?.accessProfileCodes.includes('03DE') || this.user?.accessProfileCodes.includes('02HM') || this.user?.accessProfileCodes.includes('02HO')) {
        this.getPersonDetails(this.user?.username);
      } else if(this.user?.accessProfileCodes.includes('01CP') || this.user?.accessProfileCodes.includes('01CO') || this.user?.accessProfileCodes.includes('01CV') || this.user?.accessProfileCodes.includes('01CS') || this.user?.accessProfileCodes.includes('00AS')) {
        this.getPersonDetails(this.user?.username);
      }else if(this.user?.accessProfileCodes.includes('00AD')) {
      }
    }
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

  protected getTransactions(){
    return this.securityService.transactions;
  }
  ngAfterViewInit() {
  }

  private getPersonDetails(nuit) {
    this.personService.getPersonDetails(nuit)
    .subscribe(
      (response) => {
        this.personDetails = response;
        
      },
      (error) => {
        /* Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Erro ao recuperar os dados do utilizador",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger"
          }
        }).then(
          () => {
            this.router.navigateByUrl('/pages/login')
          }
        ) */
      }
    )
  }
}
