import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DeclarantService } from 'app/core/services/declarant.service';
import { PersonService } from 'app/core/services/person.service'
import { CheckAssignedValue } from 'app/shared/custom-validator/check-assigned-value';
import { AppState, GenericComponent } from 'app/shared/implementations/GenericComponent';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-declarant-from-person',
  templateUrl: './create-declarant-from-person.component.html',
  styleUrls: ['./create-declarant-from-person.component.css']
})
export class CreateDeclarantFromPersonComponent extends GenericComponent implements OnInit {

  form!: FormGroup;
  private declarant;
  private person;
  private personNuit;
  private isFormDataAvailable = false;
  private isformConcluded: boolean = false;
  private isDisabledForm: boolean = false;
  private formTitle: String = 'Criar Dados do Declarante';

  constructor(
    location: Location,
    store: Store<AppState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private declarantService: DeclarantService,
    private personService: PersonService,
    private formBuilder: FormBuilder
  ) {
    super(location, store);
  }

  ngOnInit(): void {

    this.createForm();

    this.activatedRoute.paramMap.subscribe(params => {
      this.personNuit = params.get('personNuit');
      if (!CheckAssignedValue.isEmpty(this.personNuit)) {
        this.getActiveDeclarantByPersonNuitInstitutionSector(this.personNuit);
      }
    })
  }


  private getActiveDeclarantByPersonNuitInstitutionSector(nuit) {
    this.declarantService.getActiveDeclarantByPersonNuitInstitutionSector(nuit).subscribe(
      (response) => {
        this.declarant = response;
        this.setDeclarantFormData(response);
        this.formTitle = 'Actualizar Dados do Declarante';
        this.changeStateToConclude();
        // this.isFormDataAvailable = true;
      },
      (error) => {

        let errorTitle = 'Utilizador sem dados de Declarante.'
        let errorMsg = '';

        if (error.status == 404) {
          errorTitle = 'Utilizador sem dados de Declarante.'
          errorMsg = 'Queira por favor informar os dados de Declarante no formulário a seguir!';
        }


        if (error?.error?.message) {
          errorMsg = error?.error?.message;
        } else if (error?.error?.prettyMessage) {
          errorMsg = error?.error?.prettyMessage
        }

        Swal.fire({
          icon: "error",
          title: errorTitle,
          html: errorMsg,
          confirmButtonText: 'Fechar',
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger"
          }
        }).then(() => {
          this.getPersonByPersonNuit(this.personNuit);
        })
      }
    )
  }


  private getPersonByPersonNuit(nuit) {
    this.personService.searchPersonNUIT(nuit).subscribe(
      (response) => {
        this.person = response;
        this.setDeclarantPersonFormData(response);
        // this.isFormDataAvailable = true;
      },
      (error) => {

        let errorTitle = 'Erro ao recuperar dados da Pessoa.'
        let errorMsg = 'Não foi possível recuperar os dados da Pessoa!'

        if (error?.error?.message) {
          errorMsg = error?.error?.message;
        } else if (error?.error?.prettyMessage) {
          errorMsg = error?.error?.prettyMessage
        }

        Swal.fire({
          icon: "error",
          title: errorTitle,
          html: errorMsg,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger"
          }
        }).then(() => {
          this.location.back()
        })
      }
    )
  }


  private createForm() {
    this.form = this.formBuilder.group({
      id: [null],
      personId: [null],
      careerDescription: [null, Validators.required],
      roleDescription: [null, Validators.required],
      nominationDate: [null, Validators.required],
      functionStartDate: [null, Validators.required],
      functionEndDate: [null],

    })
  }

  private setDeclarantFormData(declarant) {
    this.form.patchValue({
      id: declarant?.id,
      personId: declarant?.personId,
      careerDescription: declarant?.careerDescription,
      roleDescription: declarant?.roleDescription,
      nominationDate: declarant?.nominationDate ? this.reformatDate(declarant?.nominationDate) : null,
      functionStartDate: declarant?.functionStartDate ? this.reformatDate(declarant?.functionStartDate) : null,
      functionEndDate: declarant?.functionEndDate ? this.reformatDate(declarant?.functionEndDate) : null,


    })
  }

  private setDeclarantPersonFormData(person) {
    this.form.patchValue({
      personId: person?.id,

    })
  }

  private onConclude() {



    if (!this.form.valid) {

      Swal.fire({
        icon: "error",
        title: "Erro no preenchimento do Formulário!",
        text: "Por favor preencha devidamente o formulário e volte a tentar!",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-danger"
        }
      })

      this.checkFormValidation(this.form);

      return;
    }

    this.changeStateToConclude();
  }

  private onUpdate() {
    this.changeStateToUnconclude();
  }

  private onConfirmAndSubmit() {
    let formValue = this.getFormValueToSubmit();
    this.submit(formValue);
  }

  private getFormValueToSubmit() {

    let formValue = this.form.value;
    formValue.nominationDate = this.form.value.nominationDate ? this.formatDate(this.form.value.nominationDate) : null;
    formValue.functionStartDate = this.form.value.functionStartDate ? this.formatDate(this.form.value.functionStartDate) : null;
    formValue.functionEndDate = this.form.value.functionEndDate ? this.formatDate(this.form.value.functionEndDate) : null;

    return formValue;
  }

  private submit(formValue: any) {



    let swalSubmitTextSuccess = "Dados do Declarante registados com sucesso!";
    let swalUpdateTextSuccess = "Dados do Declarante actualizados com sucesso!";
    let swalTextSuccess = formValue?.id ? swalUpdateTextSuccess : swalSubmitTextSuccess;

    this.declarantService.saveOnlyDeclarantData(formValue)?.subscribe(
      response => {
        Swal.fire({
          icon: "success",
          text: swalTextSuccess,
          confirmButtonText: 'Fechar',
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
          }
        }).then((result) => {
          this.router.navigate(['admin/inicio']);
        })
      },
      error => {
        let errorMsg = "Ocorreu um erro, por favor volte a tentar mais tarde!";

        if (error?.error?.message) {
          errorMsg = error?.error?.message;
        } else if (error?.error?.prettyMessage) {
          errorMsg = error?.error?.prettyMessage
        }

        Swal.fire({
          icon: "error",
          title: "Erro na submissão",
          text: errorMsg,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger"
          }
        })


      }
    )

  }

  private changeStateToConclude() {
    this.isDisabledForm = true;
    this.isformConcluded = true;
  }

  private changeStateToUnconclude() {
    this.isDisabledForm = false;
    this.isformConcluded = false;
  }

}
