import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {ClientService} from '../../security/services/client.service';
import {Observable} from "rxjs";
import {EligibleDeclarant} from "../modules/declarant/models/EligibleDeclarant";
import {take} from "rxjs/operators";
import {EligibleDeclarantObject} from "../modules/declarant/models/EligibleDeclarantObject";
import { CheckAssignedValue } from 'app/shared/custom-validator/check-assigned-value';

@Injectable({
  providedIn: 'root'
})
export class UploadlistService {
  private readonly uploadService = 'api/excel';
  private url;

  constructor(
    private http: HttpClient,
    private clientService: ClientService
  ) {
  }

  saveEligibleDeclarant(eligibleDeclarant: EligibleDeclarantObject) : Observable<EligibleDeclarantObject> {
    if(!CheckAssignedValue.isEmpty(eligibleDeclarant?.id)) {
      return this.updateEligibleDeclarant(eligibleDeclarant);
    } else {
      return this.createEligibleDeclarant(eligibleDeclarant);
    }
  }

  createEligibleDeclarantFromFile(eligibleDeclarant: EligibleDeclarant[]): Observable<EligibleDeclarant[]> {
    return this.http.post<EligibleDeclarant[]>(this.clientService.urlIPWS('eligibleDeclarant/create/eligibleDeclarantFromFile/'), eligibleDeclarant);
  }

  createEligibleDeclarant(eligibleDeclarant: EligibleDeclarantObject): Observable<EligibleDeclarantObject> {
    return this.http.post<EligibleDeclarantObject>(this.clientService.urlIPWS('eligibleDeclarant/create/eligibleDeclarant/'), eligibleDeclarant);
  }

  updateEligibleDeclarant(eligibleDeclarant: EligibleDeclarantObject): Observable<EligibleDeclarantObject> {
    return this.http.put<EligibleDeclarantObject>(this.clientService.urlIPWS('eligibleDeclarant/update/eligibleDeclarant/'), eligibleDeclarant);
  }

  getEligibleDeclarants(): Observable<EligibleDeclarantObject[]> {
    return this.http.get<EligibleDeclarantObject[]>(this.clientService.urlIPWS('eligibleDeclarant/fetch'));
  }

  sendDeclarant

  removeEligibleDeclarants(id: number): Observable<EligibleDeclarantObject> {
    return this.http.put<EligibleDeclarantObject>(this.clientService.urlIPWS('eligibleDeclarant/changeState/' + id), null);
  }


  getEligibleDeclarant(id: number): Observable<EligibleDeclarantObject> {
    return this.http.get<EligibleDeclarantObject>(this.clientService.urlIPWS('eligibleDeclarant/fetch/' + id ));
  }

  upload(file: File) {
    const xhr = new XMLHttpRequest();
    const formData: FormData = new FormData();

    formData.append('file', file);
    this.url = this.clientService.urlIPWS(`${this.uploadService}/upload`);

    const req = new HttpRequest('POST', this.url, formData, {
      headers: new HttpHeaders({'Content-Type': 'multipart/form-data'}),
      reportProgress: true,
      responseType: 'text'
    });

    xhr.onreadystatechange = (e) => {
    };

    xhr.open('POST', this.url, true);
  
    xhr.setRequestHeader('Accept', 'multipart/form-data')
    xhr.setRequestHeader('Authorization', `Bearer ${this.clientService.getAccessToken()}`)
    xhr.send(formData);
  }

}
