import {Routes} from '@angular/router';

import {AuthLayoutComponent} from './layouts/auth/auth-layout.component';
import {DeclarantLayoutComponent} from './layouts/declarant/declarant-layout.component';
import {HrLayoutComponent} from './layouts/hr/hr-layout.component';
import {AuthGuard} from './security/guards/auth-guard.service';
import {HasRoleGuard} from './security/guards/has-role.guard';
import {AdminLayoutComponent} from './layouts/admin/admin-layout.component';
import {ManagmentLayoutComponent} from "./layouts/managment-layout/managment-layout.component";
import { UpdatePasswordComponent } from './security/user/update-password/update-password.component';
import { RouterInterceptorGuard } from './security/guards/router-interceptor.guard';
import { SearchForDeclarationComponent } from './core/modules/public-powwow/search-for-declaration/search-for-declaration.component';
import { ViewAndAcceptRequestsComponent } from './core/modules/public-powwow/view-and-accept-requests/view-and-accept-requests.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'pages/login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthLayoutComponent,
    canActivateChild: [RouterInterceptorGuard],
    children: [
      {
        path: 'pages',
        loadChildren: './pages/pages.module#PagesModule'
      }]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivateChild: [RouterInterceptorGuard],
    children: [
      {
        path: '',
        loadChildren: './home/home.module#HomeModule'
      },
      {
        path: 'administration',
        loadChildren: './user/user.module#UserModule'
      }]
  },
  {
    path: '',
    component: HrLayoutComponent,
    canActivateChild: [RouterInterceptorGuard],
    children: [
      {
        path: 'rh',
        loadChildren: () => import('./core/views/hr/hr.module').then(m => m.HrModule)
      }
    ]
  },

  {
    path: '',
    component: ManagmentLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [RouterInterceptorGuard],
    children: [
      {
        path: 'admin',
        loadChildren: () => import('./core/views/admin/admin.module').then(m => m.AdminModule)
      }
    ]
  },

  {
    path: '',
    component: DeclarantLayoutComponent,
    /* canActivate: [AuthGuard, HasRoleGuard],
    data: {
      role: 'Declarants'
    }, */
    canActivateChild: [RouterInterceptorGuard],
    children: [
      {
        path: 'declarante',
        loadChildren: () => import('./core/views/declarant/declarant.module').then(m => m.DeclarantModule)
      }
    ]
  },
  {
    path: '',
    component: DeclarantLayoutComponent,
    canActivateChild: [RouterInterceptorGuard],
    children: [
      {
        path: 'notificacao',
        loadChildren: () => import('./core/modules/notification/notification.module').then(m => m.NotificationModule)
      }
    ]
  },
  {
    path: 'user',
    canActivate: [RouterInterceptorGuard],
    loadChildren: () => import('./security/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'recuperar-senha',
    component: UpdatePasswordComponent,
    canActivate: [RouterInterceptorGuard],
    data: {
      funcionalidade: 'recuperar senha'
    }
  },
  {
    path: 'search-for-declaration',
    component: SearchForDeclarationComponent,
    
 
  },
];
