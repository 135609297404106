import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-confirmation-creation-declarant',
  templateUrl: './confirmation-creation-declarant.component.html',
  styleUrls: ['./confirmation-creation-declarant.component.css']
})
export class ConfirmationCreationDeclarantComponent implements OnInit {
  data: any;

  constructor(private route: Router) {
  }

  ngOnInit(): void {
    this.data = history.state.data;
  }

  confirmation() {
    this.route.navigate(['rh/inicio']);
  }
}
