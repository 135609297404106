import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {ClientService} from '../services/client.service';
import {BlackListService} from '../services/black-list.service';
import {Observable} from 'rxjs/Rx';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private clientService: ClientService,
              private blackListRoute: BlackListService,
              private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (this.blackListRoute.isBlacklistRoutes(req.url, req.method) || this.router.routerState.snapshot.url==="/recuperar-senha" || this.router.routerState.snapshot.url==="find-declarantion-first-part"  ) {
        req = req.clone({
          setHeaders: {
            'Content-Type': `application/x-www-form-urlencoded`,
            'Authorization': `Basic ${this.clientService.clientId()}`
          }
        });
      } else {
        req = req.clone({
          setHeaders: {
            'Content-Type': `application/json`,
            'Authorization': `Bearer ${this.clientService.getAccessToken()}`
          }
        });
      }

    return next.handle(req).catch(err => {
      return Observable.throw(err);
    })
  }
}
