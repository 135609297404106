import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SecurityService } from '../../services/security.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {
  mensagemDeInformacaoDeTelefone: String = "Por favor, introduza o NUIT o número de telefone registado no Recursos Humanos. O número é parecido com *******98";
  private nuit: number;
  private temporizadorMinutos = 15;
  numeroDeTentativasRestantes: number = 15;
  tempoRestante: number = 4;
  tempo: String;
  interval;

  hideNovaSenha: boolean = true;
  hideConfirmacaoDeNovaSenha: boolean = true;

  loginForm;
  codigoTemporarioForm;
  novaSenhaForm;

  nuitInputField;
  codigoInputField;
  novaInputField;

  focus;
  focus1;
  focus2;

  constructor(private router: Router, private securityService: SecurityService) {
  }

  ngOnInit(): void {
    this.loginForm = document.getElementById("loginForm");
    this.codigoTemporarioForm = document.getElementById("codigoTemporarioForm");
    this.novaSenhaForm = document.getElementById("novaSenhaForm");

    this.nuitInputField = document.getElementById("nuit");
    this.codigoInputField = document.getElementById("codigo");
    this.novaInputField = document.getElementById("novaSenha");
  }

  ngOnDestroy() {
    this.pararTemporizador();
    if (this.nuit > 0)
      this.securityService.deleteTemporaryCode(this.nuit).subscribe((data) => {
      });
  }

  async validarNUITeTelefone(dadosDoFormularioDeLogin, $event) {
    
    $event.preventDefault();

    if (dadosDoFormularioDeLogin.telefone == "" || dadosDoFormularioDeLogin.nuit == "" || dadosDoFormularioDeLogin.telefone == null || dadosDoFormularioDeLogin.nuit == undefined) {
      Swal.fire({
        icon: "error",
        title: "Erro de validação",
        text: "Por favor, preencha todos os campos do formulário!",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-danger"
        }
      })
      return;
    }

    if (await this.validarCombinacaoDeNUITETelefone(dadosDoFormularioDeLogin)) {
      this.nuit = dadosDoFormularioDeLogin.nuit;
      await this.securityService.getNewTemporaryCode(dadosDoFormularioDeLogin.nuit, 1).subscribe(
        (data) => {
          this.pararTemporizador();
          this.iniciarTemporizador(this.temporizadorMinutos * 60, this.processarFimDeTempoDeVerificacaoDeCodigo.bind(this));
          this.numeroDeTentativasRestantes = 15;
          this.loginForm.classList.add("invisivel");
          this.codigoTemporarioForm.classList.remove("invisivel");
          this.codigoInputField.focus();
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "Erro ao gerar o código!",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-danger"
            }
          })
        });

    } else {//mostrar erros
      Swal.fire({
        icon: "error",
        title: "Dados inválidos!",
        text: "Os dados introduzidos são inválidos",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-danger"
        }
      })
    }
  }

  async validarCombinacaoDeNUITETelefone(dados): Promise<Boolean> {

    var dadosDevolvidos = await this.securityService.checkIfPhoneMatchesNUIT(dados.telefone, dados.nuit)
      .toPromise()
      .catch((error) => {

        Swal.fire({
          icon: "error",
          title: "Dados inválidos!",
          text: `${error.error.message}`,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger"
          }
        })
      });

    if (dadosDevolvidos["mensagem"] === "Combinam") {
      return true;
    } else {
      return false;
    }
  }

  async validarCodigoTemporarioENUIT(dadosDoFormularioDeVerificacaoDeCodigoTemporario) {
    var validade;
    await this.securityService.checkIfTemporaryCodeMatchesNUIT(dadosDoFormularioDeVerificacaoDeCodigoTemporario.codigo, this.nuit).subscribe(async (data) => {

      if (data["mensagem"] === "Combinam") {
        this.pararTemporizador();
        this.iniciarTemporizador(this.temporizadorMinutos * 60, this.processarFimDeTempoDeMudancaDeSenha.bind(this));
        this.codigoTemporarioForm.classList.add("invisivel");
        this.novaSenhaForm.classList.remove("invisivel");
        this.novaInputField.focus();
        await this.securityService.deleteTemporaryCode(this.nuit).subscribe((data) => {
        });
        validade = true;
      } else {
        Swal.fire({
          icon: "error",
          title: "Codigo errado!",
          text: "Por favor, verifique se os números introduzidos são iguais aos da sms recebida",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger"
          }
        })
        this.numeroDeTentativasRestantes--;
        if (this.numeroDeTentativasRestantes == 0) {
          this.processarFimDeTempoDeVerificacaoDeCodigo();
          Swal.fire({
            icon: "error",
            title: "Número de tentivas foi excedido!",
            text: "Excedeu o número de tentativas. Por favor, reinicie o processo de mudança de senha",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-danger"
            }
          })
        }
        validade = false;
      }
      validade = true;
    });
  }

  async reenviarCodigo($event) {
    $event.preventDefault();
    await this.securityService.getNewTemporaryCode(this.nuit, 1).subscribe((data) => {
    });
    Swal.fire({
      icon: "success",
      title: "Novo código foi enviado!",
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-primary"
      }
    });
    this.pararTemporizador();
    this.iniciarTemporizador(this.temporizadorMinutos * 60, this.processarFimDeTempoDeVerificacaoDeCodigo.bind(this));

    this.numeroDeTentativasRestantes = 15;
  }

  async mudarSenha(dadosDoFormularioDeNovaSenha) {
    if (dadosDoFormularioDeNovaSenha.novaSenha === dadosDoFormularioDeNovaSenha.repeticaoDaNovaSenha) {
      await this.securityService.saveNewPassword(dadosDoFormularioDeNovaSenha.novaSenha, this.nuit).subscribe(
        (data) => {
          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "A senha foi trocada com sucesso!",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary"
            }
          }).then(() => {
            this.router.navigateByUrl('/pages/login')
          })

        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "Erro do sistema ao trocar a senha!",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-danger"
            }
          })
        });

    } else {
      Swal.fire({
        icon: "error",
        title: "Senha de confirmação diferente!",
        text: "As senhas introduzidas não são idênticas. Por favor, introduza senhas idênticas",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-danger"
        }
      })
    }
  }

  iniciarTemporizador(tempoRestante, processarFimDeTempo) {
    this.tempoRestante = tempoRestante;
    this.interval = setInterval(() => {
      if (this.tempoRestante > 0) {
        this.tempoRestante--;
      } else {
        processarFimDeTempo();
      }
      this.tempo = this.transform(this.tempoRestante)
    }, 1000);
  }

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return minutes + ' min : ' + (value - minutes * 60) + " seg";
  }

  pararTemporizador() {
    clearInterval(this.interval);
  }

  cancelarMudanca() {
    this.pararTemporizador();
    // this.router.navigateByUrl('/home');
    this.router.navigate(['pages/login']);
  }

  async processarFimDeTempoDeVerificacaoDeCodigo() {
    this.pararTemporizador();
    this.codigoTemporarioForm.classList.add("invisivel");
    this.loginForm.classList.remove("invisivel");
    await this.securityService.deleteTemporaryCode(this.nuit).subscribe((data) => {
    });
    this.nuitInputField.focus();
    Swal.fire({
      icon: "error",
      title: "Fim do tempo de verificação de código temporário!",
      text: "O tempo de verificação de código temporário terminou. Por favor, caso ainda deseje recuperar a sua senha, reinicie o processo.",
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-danger"
      }
    })
  }

  processarFimDeTempoDeMudancaDeSenha() {
    this.pararTemporizador();
    this.novaSenhaForm.classList.add("invisivel");
    this.loginForm.classList.remove("invisivel");
    this.nuitInputField.focus();
    Swal.fire({
      icon: "error",
      title: "Fim do tempo de mudança de senha!",
      text: "O tempo de mudança de senha terminou. Por favor, caso ainda deseje recuperar a sua senha, reinicie o processo.",
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-danger"
      }
    })
  }

  iniciarSessao() {
    this.router.navigate(['pages/login']);
  }

}
