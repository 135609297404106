import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {take} from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class DeclarantService {

  private API = `${environment.EDB_API}`;
  // private API_DECLARANT = `${environment.EDB_API}infoprovws/`;
  private API_DECLARANT = `${environment.EDB_API}/infoprovws/`;

  constructor(private http: HttpClient) {
  }

  getDeclarantByUserId(userId) {
    return this.http.get(`${this.API_DECLARANT}getDeclarantByUser/${userId}`).pipe(take(1));
  }


  getDeclarantByNuit(nuit) {
    return this.http.get(`${this.API_DECLARANT}person/fetchDeclarant/${nuit}`).pipe(take(1));
  }

  sendCredential(nuit){
    return this.http.post(`${this.API_DECLARANT}declarant/sendsms/${nuit}`, nuit).pipe(take(1));
  }

  public saveDeclarant(declarant: any) {

    if (declarant?.id != null && declarant?.id != undefined && declarant?.id != '') {
      return this.updateDeclarant(declarant);
    } else {
      return this.createDeclarant(declarant);
    }
  }

  private createDeclarant(declarant: any) {
    return this.http.post(`${this.API_DECLARANT}person/createUser`, declarant).pipe(take(1));
  }

  private updateDeclarant(declarant) {
    return this.http.put(`${this.API_DECLARANT}person/updateDeclarant`, declarant).pipe(take(1));
  }
  
}
