import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DeclarantService} from '../../../services/declarant.service';

@Component({
  selector: 'app-view-updated-declarant',
  templateUrl: './view-updated-declarant.component.html',
  styleUrls: ['./view-updated-declarant.component.css']
})
export class ViewUpdatedDeclarantComponent implements OnInit {
  declarant: any;
  updateForm: FormGroup;


  constructor(private route: ActivatedRoute,
              private declarantService: DeclarantService,
              private router: Router,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.updateForm = this.formBuilder.group({
      person: this.formBuilder.group({
        nuit: ['', Validators.required],
        fullname: ['', Validators.required],
        nationality: ['', Validators.required],
        naturality: ['', Validators.required],
        email: ['', Validators.required],
        primaryContactNumber: ['', Validators.required],
        alternativeContactNumber: ['', Validators.required],
        organDescription: ['', Validators.required],
      }),
      nominationDate: ['', Validators.required],
      careerDescription: ['', Validators.required],
      roleDescription: ['', Validators.required],
      roleStartDate: ['', Validators.required],
      adressNeighborhood: ['', Validators.required],
      adressLocation: ['', Validators.required],
      streetNumber: ['', Validators.required],
      docType: ['', Validators.required],
      docCode: ['', Validators.required],
    });
    this.declarant = history.state.data;
  }

  update(id) {
  }


  cancel(id) {
    this.router.navigate(['rh/declarante/update-declarant', id]);
  }

}
