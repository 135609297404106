import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DeclarantService} from 'app/core/services/declarant.service';
import {Location} from '@angular/common';
import {Declarant} from '../../../models/declarant.model';
import {Store} from '@ngrx/store';
import {AppState, GenericComponent} from '../../../../shared/implementations/GenericComponent';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-declarant',
  templateUrl: './update-declarant.component.html',
  styleUrls: ['./update-declarant.component.css']
})
export class UpdateDeclarantComponent extends GenericComponent implements OnInit {

  exists: boolean = true;
  declarant: Declarant;
  toggleButton: boolean = false;
  toggleName: string = 'Actualizar dados do Declarante';
  toggleConfirmButton: string = 'Actualizar';
  functionEndDate: {}
  nominationDate: {}
  functionStartDate: {}

  constructor(private declarantService: DeclarantService,
              private router: Router,
              private route: ActivatedRoute,
              location: Location,
              store: Store<AppState>) {
    super(location, store);
  }

  ChangeData(exists: boolean) {
    this.exists = exists;
  }

  ngOnInit(): void {
    this.declarant = history.state.declarant;
    this.functionStartDate = this.declarant.functionStartDate ? this.reformatDate(this.declarant.functionStartDate) : null;
    this.nominationDate = this.declarant.nominationDate ? this.reformatDate(this.declarant.nominationDate) : null;
    this.functionEndDate = this.declarant.functionEndDate ? this.reformatDate(this.declarant.functionEndDate) : null;
  }

  cancel() {
    if (this.toggleButton) {
      this.toggleButton = false;
      this.toggleName = 'Actualizar dados do Declarante';
      this.toggleConfirmButton = 'Actualizar';
    } else {
      this.previousPage();

    }
  }

  onDateSelect(event) {
    this.declarant.functionStartDate = this.formatDate(event);
    this.declarant.nominationDate = this.formatDate(event);
    this.declarant.functionEndDate = this.formatDate(event);
  }


  onConfirm(f2, d2, d3, d4) {
    if (f2) {
      if (!this.toggleButton) {
        this.toggleButton = true;
        this.toggleName = 'Confirmar dados do Declarante';
        this.toggleConfirmButton = 'Confirmar';
      } else {
        this.declarant.functionStartDate = d2 ? d2._inputValue : null
        this.declarant.nominationDate = d3 ? d3._inputValue : null
        this.declarant.functionEndDate = d4 ? d4._inputValue : null
        console.log(d4)
        console.log(d4._inputValue)
        console.log(this.declarant)
        this.declarantService.updateDeclarant(this.declarant).subscribe((response) => {
          }, (error) => {
            Swal.fire(error.error.prettyMessage, error.error.prettyMessage, 'error')
          },
          () => {
            Swal.fire('Declarante Actualizado ', 'Declarante actualizado com sucesso', 'success')
            this.router.navigate(['rh/declarante']);
          });
      }
    }
  }
}
