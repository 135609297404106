import {Component, OnInit} from '@angular/core';
import {AppState, GenericComponent} from '../../../../shared/implementations/GenericComponent';
import {Store} from '@ngrx/store';
import {Location} from '@angular/common';
import {EligibleDeclarantObject} from '../models/EligibleDeclarantObject';
import Swal from 'sweetalert2';
import {UploadlistService} from '../../../services/uploadlist.service';
import {Router} from '@angular/router';

/**
 * @author Ivan Lifaniça
 */

@Component({
  selector: 'app-eligible-declarant-update',
  templateUrl: './eligible-declarant-update.component.html',
  styleUrls: ['./eligible-declarant-update.component.css']
})
export class EligibleDeclarantUpdateComponent extends GenericComponent implements OnInit {

  declarant: EligibleDeclarantObject;
  toggleButton: boolean = false;
  toggleName: string = 'Actualizar dados Funcionário Elegível';
  toggleConfirmButton: string = 'Actualizar';
  startDate: {};
  nominationDate: {};

  constructor(private uploadService: UploadlistService,
              private router: Router,
              location: Location,
              store: Store<AppState>) {
    super(location, store)
  }

  ngOnInit(): void {
    this.declarant = history.state.declarant
    this.startDate = this.declarant.startDate ? this.reformatDate(this.declarant.startDate) : null
    this.nominationDate = this.declarant.nominationDate ? this.reformatDate(this.declarant.nominationDate) : null
  }

  onDateSelect(event) {

    this.declarant.startDate = this.formatDate(event)
    this.declarant.nominationDate = this.formatDate(event)

  }

  onSubmit(f2, d2,d3) {
    if (f2) {
      if (!this.toggleButton) {
        this.toggleButton = true;
        this.toggleName = 'Confirmar dados do funcionário';
        this.toggleConfirmButton = 'Confirmar'
      } else {
        this.declarant.startDate = d2._inputValue
        this.declarant.nominationDate = d3._inputValue
        this.uploadService.updateEligibleDeclarant(this.declarant).subscribe((response) => {
          }, (error) => {
            Swal.fire(error.error.prettyMessage, error.error.prettyMessage, 'error')
          },
          () => {
            Swal.fire('Funcionário Elegível Actualizado ', 'Funcionário actualizado com sucesso', 'success')
            this.router.navigate(['/rh/declarante/listEligibleDeclarants'])
          });
      }
    }
  }

  cancel() {
    if (this.toggleButton) {
      this.toggleButton = false;
      this.toggleName = 'Actualizar dados Funcionário Elegível';
      this.toggleConfirmButton = 'Actualizar'
    } else {
      this.previousPage()
    }
  }
}
