import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ClientService} from '../../security/services/client.service';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators'
import {EdbUsers} from '../models/edbusers.model';
import { environment } from 'environments/environment';
import { UserModel } from '../models/user.model';
import { CheckAssignedValue } from 'app/shared/custom-validator/check-assigned-value';
import { CreateUserResources } from '../models/create-user-resources.module';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly userContext = 'users';
  private readonly profileContext = 'profiles';
  private readonly personContext = 'person';
  private readonly temporaryCodeUrl = 'temporarycode'
  // private API_AUTH = `${environment.EDB_API}/authws/`;
  private url;

  constructor(private http: HttpClient,
              private clientService: ClientService) {
  }

  getUserResourceUrl(): string {
    return this.clientService.urlAuthWS(`${this.userContext}`);
  }

  public getCreateUserResources(): Observable<CreateUserResources> {
    this.url = this.clientService.urlAuthWS(`${this.userContext}/resources-to-create`);
    return this.http.get<CreateUserResources>(this.url);
  }

  public getAllUsers(): Observable<UserModel[]> {
    this.url = this.clientService.urlAuthWS(`${this.temporaryCodeUrl}/status/check`)
    return this.http.get<UserModel[]>(this.url);
    // return this.clientService.urlIPWS(`${temporaryCodeUrl}/status/check`)
    // return this.http.get<UserModel[]>(`${this.API_AUTH}temporarycode/status/check`);
  }

  checkIfPhoneMatchesNUIT(phone: number, nuit: number): Observable<JSON> {
    this.url = this.clientService.urlIPWS(`${this.personContext}/checkifphonematchesnuit?nuit=${nuit}&phone=${phone}`);
    return this.http.get<JSON>(this.url);
  }

  getNewTemporaryCode(nuit: number, receiver): Observable<JSON> {
    this.url = this.clientService.urlAuthWS(`${this.temporaryCodeUrl}/create?nuit=${nuit}&receiver=${receiver}`);
    return this.http.post<JSON>(this.url, null);
    // return this.http.post<JSON>(`${this.API_AUTH}temporarycode/create?nuit=${nuit}&receiver=${receiver}`, null);
  }

  checkIfTemporaryCodeMatchesNUIT(temporayCode: number, nuit: number): Observable<JSON> {
    this.url = this.clientService.urlAuthWS(`${this.temporaryCodeUrl}/checkiftemporarycodematchesnuit?nuit=${nuit}&temporaryCode=${temporayCode}`)
    // return this.http.get<JSON>(`${this.API_AUTH}temporarycode/checkiftemporarycodematchesnuit?nuit=${nuit}&temporaryCode=${temporayCode}`);
    return this.http.get<JSON>(this.url);
  }

  deleteTemporaryCode(nuit: number): Observable<JSON> {
    this.url = this.clientService.urlAuthWS(`${this.temporaryCodeUrl}/delete?nuit=${nuit}`);
    return this.http.post<JSON>(this.url, null);
    // return this.http.post<JSON>(`${this.API_AUTH}temporarycode/delete?nuit=${nuit}`, null);
  }

  saveNewPassword(newPassword: string, nuit: number): Observable<JSON> {
    this.url = this.clientService.urlAuthWS(`${this.userContext}/updatePassword?nuit=${nuit}&newPassword=${newPassword}`);
    return this.http.post<JSON>(this.url, null);
    // return this.http.post<JSON>(`${this.API_AUTH}users/updatePassword?nuit=${nuit}&newPassword=${newPassword}`,null);
  }


  public sendPasswordNotification(nuit : String) {
    this.url = this.clientService.urlAuthWS(`${this.userContext}/send-password-notification/${nuit}`);
    return this.http.post(this.url, null);
  }



  public finduserByUsername(username): Observable<EdbUsers[]> {
    this.url = this.clientService.urlAuthWS(`users/search/` + username)
    return this.http.get<EdbUsers[]>(this.url);
  }

  public finduserById(id): Observable<EdbUsers[]> {
    this.url = this.clientService.urlAuthWS(`${this.userContext}/find/` + id)
    return this.http.get<EdbUsers[]>(this.url);
  }
  
  public fetchWithPersonByNuit(nuit): Observable<EdbUsers[]> {
    this.url = this.clientService.urlAuthWS(`${this.userContext}/fetch-with-person/` + nuit)
    return this.http.get<EdbUsers[]>(this.url);
  }

  public saveUser(user: EdbUsers): Observable<EdbUsers> {
    if(!CheckAssignedValue.isEmpty(user.id)) {
      return this.updateUser(user);
    } else {
      return this.createUser(user);
    }
  }

  public createUser(user: EdbUsers): Observable<EdbUsers> {
    this.url = this.clientService.urlAuthWS(`${this.userContext}/create`)
    return this.http.post<EdbUsers>(this.url, user);
  }

  public updateUser(user: EdbUsers): Observable<EdbUsers> {
    this.url = this.clientService.urlAuthWS(`${this.userContext}/update/${user?.id}`)
    return this.http.put<EdbUsers>(this.url, user);
  }

  public activateuser(id): Observable<EdbUsers[]> {
    this.url = this.clientService.urlAuthWS(`${this.userContext}/active` + id)
    return this.http.put<EdbUsers[]>(this.url, null);
  }

  public inactivateuser(id): Observable<EdbUsers[]> {
    this.url = this.clientService.urlAuthWS(`${this.userContext}/inactive` + id)
    return this.http.put<EdbUsers[]>(this.url, null);
  }

  public setUserTransaction(userId, transactions): Observable<EdbUsers> {
    this.url = this.clientService.urlAuthWS(`${this.userContext}/set-transaction/${userId}`)
    return this.http.put<EdbUsers>(this.url, transactions);
  }


  public getProfilesBySectionId(sectionId: any) {
    this.url = this.clientService.urlAuthWS(`${this.profileContext}/get-profiles-by-section/` + sectionId)
    return this.http.get(this.url);
  }


  

  getCrvAgentByUserId(userId) {
    this.url = this.clientService.urlIPWS(`${this.personContext}/getCrvAgentByUser/${userId}`);
    return this.http.get(this.url).pipe(take(1));
  }

  getCrvAgentByNuit(nuit) {
    this.url = this.clientService.urlIPWS(`${this.personContext}/fetchCRVAgent/${nuit}`);
    return this.http.get(this.url).pipe(take(1));
  }

  getDeclarantByUserId(userId) {
    this.url = this.clientService.urlIPWS(`${this.personContext}/getDeclarantByUser/${userId}`);
    return this.http.get(this.url).pipe(take(1));
  }

  getDeclarantByNuit(nuit) {
    this.url = this.clientService.urlIPWS(`${this.personContext}/fetchDeclarant/${nuit}`);
    return this.http.get(this.url).pipe(take(1));
  }

  
  private createCrvAgent(crvAgent: any) {
    this.url = this.clientService.urlIPWS(`${this.personContext}/create`);
    return this.http.post(this.url, crvAgent).pipe(take(1));
  }
  
  private updateCrvAgent(crvAgent) {
    this.url = this.clientService.urlIPWS(`${this.personContext}/updateCRVAgent`);
    return this.http.put(this.url, crvAgent).pipe(take(1));
  }

  private createDeclarant(declarant: any) {
    this.url = this.clientService.urlIPWS(`${this.personContext}/create`);
    return this.http.post(this.url, declarant).pipe(take(1));
  }

  private updateDeclarant(declarant) {
    this.url = this.clientService.urlIPWS(`${this.personContext}/updateDeclarant`);
    return this.http.post(this.url, declarant).pipe(take(1));
  }

  public saveDeclarant(declarant: any) {

    if(declarant?.id != null && declarant?.id != undefined && declarant?.id != "") {
      return this.updateDeclarant(declarant);
    } else {
      return this.createDeclarant(declarant);
    }
  }

  public saveCrvAgent(crvAgent: any) {
    
    if(crvAgent?.id != null && crvAgent?.id != undefined && crvAgent?.id != "") {      
      return this.updateCrvAgent(crvAgent);
    } else {
      return this.createCrvAgent(crvAgent);
    }
  }


}
