import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from '../services/client.service';
import Swal from 'sweetalert2';
import { CheckAssignedValue } from 'app/shared/custom-validator/check-assigned-value';
import { Location } from '@angular/common';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private router: Router, private toastrService: ToastrService, private clientService: ClientService, private location: Location) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError(ex => {
          let invalidTokenMsg = 'A sua sessao expirou/Autenticação inválida';
          let serviceUnvailableMsg = 'Serviço indisponível';
          let internalServerMsg = 'Erro interno do servidor';
          let msg = `[E0]: ${ex}`;
          if (typeof ex === 'string') {
            msg = `[E1]: ${ex}`;
            this.SweetAlertError('', msg);
          } else if (ex instanceof HttpErrorResponse) {

            if (ex.status == 401) {

              this.clientService.resetAccessToken();
              msg = `[E7]: ${invalidTokenMsg}`;
              this.SweetAlertError('Sessão', msg);
              this.router.navigate(['/pages/login'])

            } else if (ex.status == 503) {

              msg = `[E9]: ${serviceUnvailableMsg}`;
              this.SweetAlertError('', msg);

            } else if (!CheckAssignedValue.isEmpty(ex.error.error)) {



              if (ex.error.error === 'invalid_grant') {
                this.clientService.resetAccessToken();
                msg = '[E2]: Utilizador ou senha inválidos';
                this.SweetAlertError('Sessão', msg);

              } else if (ex.error.error === 'invalid_token') {
                this.clientService.resetAccessToken();
                msg = `[E3]: ${invalidTokenMsg}`;

                this.SweetAlertError('Sessão', msg);
                this.router.navigate(['/pages/login'])
              } else if(ex.error.error === 'Internal Server Error') {

                if(!CheckAssignedValue.isEmpty(ex.error.message)) {
                  msg = `[E11]: ${ex.error.message}`;
                } else {
                  msg = `[E12]: ${internalServerMsg}`;
                }

                
                this.SweetAlertError('', msg)

              } else {
                if(ex.status == 500) {
                  msg = `[E13]: ${internalServerMsg}`;
                } else {
                  msg = `[E14]:`;
                }                
              }
            } else if (!CheckAssignedValue.isEmpty(ex.error.prettyMessage)) {

              msg = `[E4]: ${ex.error.prettyMessage}`;
              if (ex.error.prettyMessage.includes('invalid_token')) {
                msg = `[E8]: ${invalidTokenMsg}`;
              }

              this.SweetAlertError('', msg)
            } else {
              if(ex.status == 500) {
                msg = `[E15: ${internalServerMsg}`;
                this.SweetAlertError('', msg)
              }
              
            }

          } else {
            msg = `[E6]: ${ex.message}`;
            this.SweetAlertError('', msg)
          }         
          return throwError(ex);
        })
      );
  }

  private SweetAlertError(title, msg) {
    Swal.fire({
      icon: "error",
      title: title,
      text: msg,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-danger"
      }
    }).then(() => {
      if(!CheckAssignedValue.isEmpty(this.clientService.getAccessToken())) {
        this.location.back();
      }
    })
  }
}
