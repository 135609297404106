import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HrComponent} from './hr.component';
import {SearchDeclarantComponent} from '../../modules/declarant/search-declarant/search-declarant.component';
import {UpdateDeclarantComponent} from '../../modules/declarant/update-declarant/update-declarant.component';
import {ViewCreatedDeclarantComponent} from '../../modules/declarant/view-created-declarant/view-created-declarant.component';
import {ViewUpdatedDeclarantComponent} from '../../modules/declarant/view-updated-declarant/view-updated-declarant.component';
import {HomeHrComponent} from './home-hr/home-hr.component';
import {DeclarantListComponent} from '../../modules/declarant/declarant-list/declarant-list.component';
import {ConfirmationUpdateDeclarantComponent} from '../../modules/declarant/confirmation-update-declarant/confirmation-update-declarant.component';
import {ConfirmationCreationDeclarantComponent} from '../../modules/declarant/confirmation-creation-declarant/confirmation-creation-declarant.component';
import {HasRoleGuard} from 'app/security/guards/has-role.guard';
import {UploadListComponent} from '../../modules/declarant/upload-list/upload-list.component';
import {EligibleDeclarantListComponent} from '../../modules/declarant/eligible-declarant-list/eligible-declarant-list.component';
import {EligibleDeclarantCreateComponent} from '../../modules/declarant/eligible-declarant-create/eligible-declarant-create.component';
import {EligibleDeclarantUpdateComponent} from '../../modules/declarant/eligible-declarant-update/eligible-declarant-update.component';
import { CreateDeclarantComponent } from 'app/core/modules/declarant/create-declarant/create-declarant.component';
import { HRDeclarantReportComponent } from 'app/core/modules/declarant/hrdeclarant-report/hrdeclarant-report.component';
import { HRDeclarationReportComponent } from 'app/core/modules/declaration/hrdeclaration-report/hrdeclaration-report.component';

const routes: Routes = [
  {
    path: 'inicio',
    component: HomeHrComponent,
    canActivate: [HasRoleGuard],
    data: {
      role: ['HOME_RH']
    }
  },
  {
    path: 'relatorio',
    children: [
      {
        path: 'declarantes',
        component: HRDeclarantReportComponent,
        canActivate: [HasRoleGuard],
        data: {
          role: ['GENERATE_HRM_DECLARANT_REPORT']
        }
      },
      {
        path: 'declaracoes',
        component: HRDeclarationReportComponent,
        canActivate: [HasRoleGuard],
        data: {
          role: ["GENERATE_HRM_DECLARATION_REPORT"]
        }
      }    
    ]
  },  
  {
    path: 'declarante',
    component: HrComponent,
    children: [
      {
        path: 'create-declarant',
        component: CreateDeclarantComponent,
        canActivate: [HasRoleGuard],
        data: {
          role: ['SUBMIT_DECLARANT_RH']
        }
      },
      {
        path: 'create-declarant/:id',
        component: CreateDeclarantComponent,
        canActivate: [HasRoleGuard],
        data: {
          role: ['SUBMIT_DECLARANT_RH']
        }
      },
      {
        path: 'update-declarant',
        component: UpdateDeclarantComponent,
        canActivate: [HasRoleGuard],
        data: {
          role: ['GET_DECLARANT_RH']
        }
      },
      {
        path: 'view-created-declarant',
        component: ViewCreatedDeclarantComponent,
        canActivate: [HasRoleGuard],
        data: {
          role: ['GET_DECLARANT_RH', 'SUBMIT_DECLARANT_RH']
        }
      },
      {
        path: 'view-updated-declarant/:id',
        component: ViewUpdatedDeclarantComponent,
        canActivate: [HasRoleGuard],
        data: {
          role: ['GET_DECLARANT_RH', 'SUBMIT_DECLARANT_RH']
        }
      },
      {
        path: '',
        component: DeclarantListComponent,
        canActivate: [HasRoleGuard],
        data: {
          role: ['GET_DECLARANT_RH']
        }
      },
      {
        path: 'confirmation-update-declarant/:id',
        component: ConfirmationUpdateDeclarantComponent,
        canActivate: [HasRoleGuard],
        data: {
          role: ['GET_DECLARANT_RH', 'SUBMIT_DECLARANT_RH']
        }
      },
      {
        path: 'confirmation-creation-declarant',
        component: ConfirmationCreationDeclarantComponent,
        canActivate: [HasRoleGuard],
        data: {
          role: ['GET_DECLARANT_RH', 'SUBMIT_DECLARANT_RH']
        }
      },
      {
        path: 'upload-list',
        component: UploadListComponent,
        data: {
          role: ['SUBMIT_DECLARANT_BULK_RH']
        }
      },
      {
        path: 'listEligibleDeclarants',
        component: EligibleDeclarantListComponent,
        data: {
          role: ['GET_DECLARANT_RH']
        }
      }
      ,
      {
        path: 'create-eligible-declarants',
        component: EligibleDeclarantCreateComponent,
        data: {
          role: ['SUBMIT_DECLARANT_BULK_RH']
        }
      }
      ,
      {
        path: 'update-eligible-declarants',
        component: EligibleDeclarantCreateComponent,
        //component: EligibleDeclarantUpdateComponent,
        data: {
          role: ['SUBMIT_DECLARANT_BULK_RH']
        }
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HrRoutingModule {
}
