import { Component } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
    moduleId: module.id,
    selector: 'footer-cmp',
    templateUrl: 'footer.component.html'
})

export class FooterComponent{
    private webAppVersion;
    test : Date = new Date();

    constructor(){};

    ngOnInit() {
        this.webAppVersion = environment.EDB_VERSION;
    }
}
