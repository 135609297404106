import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DeclarantService} from '../declarant.service';
import provincesAndDistricts from '../../../../files/provincesAndDistricts.json';
import Swal from 'sweetalert2';

import countries from '../../../../files/countries.json';
import {ETributacaoService} from 'app/core/services/e-tributacao.service';
import {FormValidations} from 'app/shared/fom-validations/form-validations';
import {UserService} from 'app/core/services/users.service';

declare var $: any;

@Component({
  selector: 'app-create-declarant',
  templateUrl: './create-declarant.component.html',
  styleUrls: ['./create-declarant.component.css']
})
export class CreateDeclarantComponent implements OnInit {
  creationForm: FormGroup;
  form!: FormGroup;
  // declarant: Declarant = new Declarant();
  declarant: any;

  // submit = false;
  private isformConcluded: boolean = false;
  private isDisabledForm: boolean = false;
  private isFormDataAvailable = false;
  private isPersonDataFoundByNuit: Boolean = false;


  private countries: { code: string, name: string }[] = countries;
  private provinces: { id: number, map_code: number, name: string, districts: {id: number, name: string} [] }[] = provincesAndDistricts;
  districts: {id: number, name: string} [];
  private idDocTypes = [{ id: 1, code: 1, name: "BI" }, { id: 2, code: 2, name: "Passaporte" }, { id: 3, code: 3, name: "DIRE" }, { id: 4, code: 4, name: "Certidão de Nascimento" }, { id: 5, code: 5, name: "Cédula Pessoal" }, { id: 6, code: 6, name: "Outro" }];
  private genders = [{ id: 1, code: "M", name: "Masculino" }, { id: 2, code: "F", name: "Feminino" }];
  private profiles: any = [{id: 1, name: "Declarante"}];


  private filling_instructions_declarant = [
    { code: 1, desc: 'Nome: Indicar o nome completo do declarante' },
    { code: 2, desc: 'NUIT: Indicar o nuit do declarante' },
    { code: 3, desc: 'Nacionalidade: Indicar a nacionalidade do declarante' },
    { code: 4, desc: 'Naturalidade: Indicar a naturalidade do declarante' },
    { code: 5, desc: 'Endereço: Indicar o endereço de residência do declarante' },
    { code: 6, desc: 'Bairro: Indicar o bairro do declarante' },
    { code: 7, desc: 'Rua: Indicar o rua do declarante' },
    { code: 8, desc: 'Unidade organica - Indicar a Unidade Organica Exemplo: Conselho, Departamento, Direcção, Gabinete, Inspecção e Secretariado' },
    { code: 9, desc: 'Carreira: Indicar Carreira do declarante' },
    { code: 10, desc: 'Cargo - Indicar o cargo ou função que desempenhou nos últimos 2 anos' },
    { code: 11, desc: 'Data de de início de funções - Indicar a data em que iniciou as funções' },
    { code: 12, desc: 'Numero de Telefone - Indicar o numero de telefone actualizado do Declarante' },
    { code: 13, desc: 'Numero de Telefone Alternativo- Indicar o numero de telefone alternativo actualizado do Declarante' },
    { code: 14, desc: 'O tipo de Documento - Indicar o numero de telefone actualizado do Declarante' },
    { code: 15, desc: 'O Numero de Documento - Indicar o numero de telefone actualizado do Declarante' },
    // { code: 16, desc: 'O Local de emissão - Indicar o local de emissão' },
    // { code: 17, desc: 'Data de emissao- Indicar a data em que foi nomeado na formatação DD-MM-AAAA' },
    // { code: 18, desc: 'Data de validade - Indicar a data em que foi nomeado na formatação DD-MM-AAAA' },
  ]

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private declarantService: DeclarantService,
    private eTribuitacaoService: ETributacaoService) {
  }

  ngOnInit(): void {
    /* this.creationForm = this.formBuilder.group({
        charge: ['', Validators.required],
        nominationDate: ['', Validators.required],
        person: this.personForm(this.declarant)
      }) */

    this.createFormData();

    this.route.paramMap.subscribe(
      (params) => {
        let id = params.get('id');
        let sectionId = params.get('sectionId');
        if (id != null && id != undefined && id != "") {
          this.getDeclarant(id);
        }

        if(sectionId != null && sectionId != undefined && sectionId != "") {
          this.getProfiles(sectionId);
        }
      }
    )



    if ($(".datepicker").length != 0) {
      $('.datepicker').datetimepicker({
        format: 'MM/DD/YYYY',
        icons: {
          time: "fa fa-clock-o",
          date: "fa fa-calendar",
          up: "fa fa-chevron-up",
          down: "fa fa-chevron-down",
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          today: 'fa fa-screenshot',
          clear: 'fa fa-trash',
          close: 'fa fa-remove'
        },
        debug: true
      });
    }
  }

  private getDeclarant(declarantNuit) {
    this.declarantService.getDeclarantByNuit(declarantNuit).subscribe(
      (response) => {
        this.declarant = response;
        this.setDeclarantData(response);
      }
    )
  }

  private getProfiles(sectionId) {
    this.userService.getProfilesBySectionId(sectionId).subscribe(
      (response) => {
        this.profiles = response;
      }
    )
  }


  private createFormData() {
    this.form = this.formBuilder.group({
      id: [null],
      profiles: this.buildProfiles(),
      docType: [null, Validators.required],
      isDocTypeOther: [null],
      docTypeOther: [null],
      docCode: [null, Validators.required],

      declarantCountry: [null, Validators.required],
      declarantProvince: [null],
      declarantCity: [null, Validators.required],
      declarantDistrict: [null],
      declarantLocality: [null],
      declarantNeighborhood: [null, Validators.required],
      declarantRoadOrAvenue: [null],
      declarantBlock: [null],
      declarantHouseNumber: [null, [FormValidations.number]],
      // adressNeighborhood: [null, Validators.required],
      streetNumber: [null],
      careerDescription: [null, Validators.required],
      roleDescription: [null, Validators.required],
      roleStartDate: [null, Validators.required],
      hrmanager: [false],
      person: this.personForm()
    })
  }

  private personForm() {
    return this.formBuilder.group({
      id: [null],
      profiles: this.buildProfiles(),
      nuit: [null, [Validators.required, FormValidations.nuit]],
      fullName: [null, Validators.required],
      gender: [null, Validators.required],
      nationality: [null, Validators.required],
      naturality: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      primaryContactNumber: [null, [Validators.required, FormValidations.phone]],
      alternativeContactNumber: [null, [FormValidations.phone]],
      // organDescription: [null, Validators.required],
    })
  }


  setDeclarantData(declarant) {
    this.form.patchValue({
      id: declarant?.id,
      docType: declarant?.docType,
      docTypeOther: declarant?.docTypeOther,
      docCode: declarant?.docCode,
      adressLocation: declarant?.adressLocation,

      declarantCountry: declarant?.declarantCountry,
      declarantProvince: declarant?.declarantProvince,
      declarantCity: declarant?.declarantCity,
      declarantDistrict: declarant?.declarantDistrict,
      declarantLocality: declarant?.declarantLocality,
      declarantNeighborhood: declarant?.declarantNeighborhood,
      declarantRoadOrAvenue: declarant?.declarantRoadOrAvenue,
      declarantBlock: declarant?.declarantBlock,
      declarantHouseNumber: declarant?.declarantHouseNumber,

      careerDescription: declarant.careerDescription,
      roleDescription: declarant.roleDescription,
      roleStartDate: declarant.roleStartDate ? this.dateTransformToObject(declarant.roleStartDate) : null,
      person: declarant.person
    })

  }

  private buildProfiles() {
    const values = this.profiles.map( profile => {
      return new FormControl(this.checkProfileExistenceInDeclarant(profile.id))
    });
    return this.formBuilder.array(values);
  }

  private checkProfileExistenceInDeclarant(profileId: number) {
    if(this.declarant?.profiles == null || this.declarant?.profiles == undefined) {
      return false;
    }
    return this.declarant?.profiles.some( profile => profile.id === profileId);
  }



  get person() {
    return this.form.get("person") as FormGroup;
  }

  findPersonByNuit(nuit) {

    if (this.form.controls.person.get("nuit").invalid) {

      Swal.fire({
        icon: "error",
        title: "Erro de validação!",
        text: "Por favor informe um NUIT válido!",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-danger"
        }
      })

      let nuitFormControl: FormControl = this.form.controls.person.get("nuit") as FormControl;

      this.checkFomControlValidation(nuitFormControl);

      return;
    }

    this.eTribuitacaoService.getByNuitDgi(nuit)?.subscribe(
      response => {
        this.setPersonAndDeclarantDataFromETributacao(response);
      },
      error => {
        Swal.fire({
          icon: "error",
          title: "Erro de Consulta",
          html: "Não foi possível recuperar a informação pelo NUIT!",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger"
          }
        })
      }
    ).add(() => {
    })

  }


  enableFindPersonByNuit() {
    this.isPersonDataFoundByNuit = false;
    this.clearPersonAndDeclarantDataFromETributacao();
  }

  private setPersonAndDeclarantDataFromETributacao(data) {
    let fullName = `${data?.names} ${data?.surname}`
    let identificationIDType = `${data?.documentType}`
    let identificationID = `${data?.documentId}`

    this.person.patchValue({
      fullName: fullName,
    });

    this.form.patchValue({
      docType: identificationIDType,
      docCode: identificationID
    });
  }

  private clearPersonAndDeclarantDataFromETributacao() {
    this.person.patchValue({
      fullName: null,
    });

    this.form.patchValue({
      docType: null,
      docCode: null
    });
  }



  private dateTransformToString(date: any) {

    if (date == null || date == undefined || date == "") {
      return null;
    }

    let dateString = "";


    if (typeof (date) === 'object') {
      let day = date.day ? String(date.day).padStart(2, '0') : '00';
      let month = date.month ? String(date.month).padStart(2, '0') : '00';
      let year = date.year ? date.year : '0000';
      dateString = `${year}-${month}-${day}`;
    } else {
      return date;
    }

    return dateString;
  }

  private dateTransformToObject(date: any) {

    if (!date) {
      return null;
    }

    let newDate = new Date(date)
    return { year: newDate.getFullYear(), month: newDate.getMonth() + 1, day: newDate.getDate() }
  }


  private getFormValueToSubmit() {

    var tempProfiles = this.profiles.slice();
    const selectedProfileIds = this.form.value.profiles
      .map((checked, i) => checked ? this.profiles[i].id : null)
      .filter(v => v !== null);


    for (var i = this.profiles.length - 1; i >= 0; i--) {
      if (selectedProfileIds.indexOf(this.profiles[i].id) < 0) {
        tempProfiles.splice(i, 1);
      }
    }

    this.form.value.profiles = tempProfiles;

    let formValue = this.form.value;
    formValue.roleStartDate = this.dateTransformToString(formValue.roleStartDate);
    return formValue;
  }

  private getFormValueToForm(formData) {
    formData.roleStartDate = this.dateTransformToObject(formData.roleStartDate);
    return formData;
  }


  private checkFormValidation(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(field => {

      const control = formGroup.get(field);
      control.markAsDirty();
      control.markAsTouched();

      if (control instanceof FormGroup || control instanceof FormArray) {
        this.checkFormValidation(control);
      }

    })
  }

  private checkFomControlValidation(formControl: FormControl) {
    formControl.markAsDirty();
    formControl.markAsTouched();
  }


  private onConclude() {

    let formValue = this.getFormValueToSubmit();

    if (!this.form.valid) {

      Swal.fire({
        icon: "error",
        title: "Erro no preenchimento do Formulário!",
        text: "Por favor preencha devidamente o formulário e volte a tentar!",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-danger"
        }
      })

      this.checkFormValidation(this.form);

      return;
    }

    this.isDisabledForm = true;
    this.isformConcluded = true;
  }

  private onUpdate() {
    this.isDisabledForm = false;
    this.isformConcluded = false;
  }

  private onConfirmAndSubmit() {

    let formValue = this.getFormValueToSubmit();
    this.submit(formValue);




  }





  private submit(formValue: any) {

    let swalTextSuccess = "";

    this.declarantService.saveDeclarant(formValue)?.subscribe(
      response => {
        Swal.fire({
          icon: "success",
          title: "Submetido com sucesso!",
          text: swalTextSuccess,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary"
          }
        }).then(() => {
          this.router.navigate(['rh/declarante/listEligibleDeclarants']);
        })
      },
      error => {
        const errorPrettyMsg = error.error?.prettyMessage ? error.error?.prettyMessage : "Ocorreu um erro, por favor volte a tentar mais tarde!";
        Swal.fire({
          icon: "error",
          title: "Erro na submissão",
          text: errorPrettyMsg,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger"
          }
        })


      }
    )

  }

  private swalError(title, msg) {
    Swal.fire({
      icon: "error",
      title: title,
      text: msg,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-danger"
      }
    })
  }

  setDistrictList(provincia:string){
    if(this.provinces.find(x => x.name === provincia) != null){
      this.districts = this.provinces.find(x => x.name === provincia).districts;
    }
  }

}
