import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClientService} from './services/client.service';
import { UserModule } from './user/user.module';

export function tokenGetter() {
  return sessionStorage.getItem('token');
}

@NgModule({
  declarations: [
  
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    UserModule
  ],
  providers: [
    SecurityModule,
    ClientService
  ]
})
export class SecurityModule { }
