import {Component, OnInit} from '@angular/core';
import {AppState, GenericComponent} from '../../../../shared/implementations/GenericComponent';
import {Store} from '@ngrx/store';
import {Location} from '@angular/common';
import {UploadlistService} from '../../../services/uploadlist.service';
import {EligibleDeclarantObject} from '../models/EligibleDeclarantObject';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {DeclarantService} from '../../../services/declarant.service';

/**
 * @author Ivan Lifaniça
 */

@Component({
  selector: 'app-eligible-declarant-list',
  templateUrl: './eligible-declarant-list.component.html',
  styleUrls: ['./eligible-declarant-list.component.css', '../../../../shared/implementations/generic.component.css']
})
export class EligibleDeclarantListComponent extends GenericComponent implements OnInit {

  eligibleDeclarants: EligibleDeclarantObject[];
  eligibleDeclarant: EligibleDeclarantObject;

  constructor(private uploadService: UploadlistService,
              private declarantService: DeclarantService,
              private router: Router,
              location: Location,
              store: Store<AppState>) {
    super(location, store);
  }

  ngOnInit(): void {
    this.eligibleDeclarants = [];

    this.dataTable = {
      headerRow: ['NOME', 'APELIDO', 'NUIT', 'CARREIRA', 'UNIDADE', 'ESTADO'],
      footerRow: ['NOME', 'APELIDO', 'NUIT', 'CARREIRA', 'UNIDADE', 'ESTADO'],
    }

    this.uploadService.getEligibleDeclarants().subscribe((declarants) => {
      if (declarants != null) {
        this.eligibleDeclarants = declarants;
      }
      setTimeout(() => {
        this.rendersTable()
      })
    }, () => {
    }, () => {
    })

    this.incomingObject$.subscribe(value => {
      this.eligibleDeclarant = JSON.parse(JSON.stringify(value.incomingObject))
    })

  }

  selectedDeclarant(declarant: EligibleDeclarantObject) {

  }

  edit() {
    this.router.navigate(['/rh/declarante/update-eligible-declarants'], {state: {declarant: this.eligibleDeclarant}})
  }

  create() {
    this.router.navigate(['/rh/declarante/create-eligible-declarants'])
  }

  removeEarning() {
    this.uploadService.removeEligibleDeclarants(this.eligibleDeclarant.id).subscribe(declarant => {
      }, (error) => {
        Swal.fire('Funcionário não removido!', '', 'error')
      },
      () => {
        setTimeout(() => {
          Swal.fire('Funcionário removido com sucesso', 'Funcionário removido com sucesso', 'success').then(() => {
            window.location.reload();
          })
        })
      })
  }

  inactivateEarning() {
    let inactivate = (function (_ruleID: number) {
      return this.removeEarning()
    }).bind(this)

    this.showAlert(
      this.alertProps = {
        text: 'Pretende excluir este Declarante?',
        alertText: 'Confirmado.',
        callback: inactivate
      }
    )
  }

  confirmDeclarant() {
    this.router.navigate(['/rh/declarante/view-created-declarant'], {state: {declarant: this.eligibleDeclarant}});
  }

  sendCredential(){
    this.declarantService.SendCredentials(this.eligibleDeclarant.nuit).subscribe(declarant => {
    }, (error) => {
      Swal.fire(error.error.prettyMessage, error.error.prettyMessage, 'error');
    },
    () => {
        Swal.fire('Enviadas Credencias com sucesso', 'Enviadas Credencias com sucesso', 'success');
      }
    );
    }
}
