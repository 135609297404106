import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeclarationService } from 'app/core/services/declaration.service';
import { SecurityService } from 'app/security/services/security.service';
import Swal from 'sweetalert2';
import { DeclarantService } from '../../declarant/declarant.service';

@Component({
  selector: 'app-detailed-request-view',
  templateUrl: './detailed-request-view.component.html',
  styleUrls: ['./detailed-request-view.component.css']
})
export class DetailedRequestViewComponent implements OnInit {

  private declarationId: any;
  private requestNuit: any;
  private specificRequest: any;


  constructor(private router: Router, private route: ActivatedRoute, private securityService: SecurityService, private declarationService: DeclarationService, private declarantService: DeclarantService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      (params) => {
        this.declarationId = params.get('declarationId');
        this.requestNuit = params.get('nuit')
      });

    this.getSpecificRequest(this.requestNuit, this.declarationId)
  }

  getSpecificRequest(nuit, declarationId){

    this.declarationService.getSpecificRequest(nuit, declarationId).subscribe(
      response => {
        this.specificRequest = response;
      },
      error => {
        const errorPrettyMsg = error.error?.message ? error.error?.message : "O sistema encontra-se indisponível, por favor volte a tentar!";
        Swal.fire({
          icon: "error",
          title: "Indisponibilidade para busca de Requisições para visualição da segunda parte",
          text: errorPrettyMsg,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger"
          }
        });
        this.goABack();
      }
    );

  }

  goABack(){

    this.router.navigate([`/${this.securityService.userAppSction.urlSegment}/public-powwow/view-and-accept-requests`]);

  }

  acceptRequest(){

    Swal.fire({
      icon: "info",
      title: "Funcioalidade em Construção",
      text: "",
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-danger"
      }
    });

  }

  rejectRequest(nuit){
    this.declarationService.rejectSecondPartVisualization(nuit).subscribe(
      response => {
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Solicitação rejeitada com sucesso",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger"
          }
        });
          this.goABack();

      },
      error => {
        const errorPrettyMsg = error.error?.message ? error.error?.message : "O sistema encontra-se indisponível, por favor volte a tentar!";
        Swal.fire({
          icon: "error",
          title: "Indisponibilidade do Sitema",
          text: errorPrettyMsg,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger"
          }
        });
        this.goABack();
      }
    );
  }

}
