import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Transaction } from 'app/core/models/Transaction.model';
import {Observable} from 'rxjs';
import Swal from 'sweetalert2';
import {SecurityService} from '../services/security.service';


@Injectable({
    providedIn: 'root',
})
export class HasRoleGuard implements CanActivate {

    constructor(private securityService: SecurityService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let isAuthorized = false;
        let dataRole: Array<String> = route.data.role;
        let transactionsPromisse:Promise<Transaction[]> = this.securityService.getTransactionsOnStart();
        let transactions;
        return transactionsPromisse.then(data => {
            transactions = data.sort((a, b) => (a.position > b.position) ? 1 : -1);
            if (transactions) {
                transactions.forEach(transaction => {
                    if(dataRole.includes(transaction.code)) {
                        isAuthorized = true;
                    }
                });
            }
            
            if (!isAuthorized) {
                Swal.fire({
                    icon: 'error',
                    title: 'Sem Permissão',
                    // text: "Ocorreu um erro, por favor volte a tentar!",
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-danger'
                    }
                }).then(() => {
                    this.router.navigate(['/pages/login'])
                })
            }
        return isAuthorized || false
        });
        
    }
}