import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeclarantRoutingModule} from './declarant-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DeclarantComponent} from './declarant.component';
import {DeclarantListComponent} from './declarant-list/declarant-list.component';
import {UpdateDeclarantComponent} from './update-declarant/update-declarant.component';
import {SearchDeclarantComponent} from './search-declarant/search-declarant.component';
import {ViewCreatedDeclarantComponent} from './view-created-declarant/view-created-declarant.component';
import {ViewUpdatedDeclarantComponent} from './view-updated-declarant/view-updated-declarant.component';
import {
  ConfirmationCreationDeclarantComponent
} from './confirmation-creation-declarant/confirmation-creation-declarant.component';
import {
  ConfirmationUpdateDeclarantComponent
} from './confirmation-update-declarant/confirmation-update-declarant.component';
import {HrRoutingModule} from '../../views/hr/hr-routing.module';
import {NgbDatepickerModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {UploadListComponent} from './upload-list/upload-list.component';
import { ErrorMsgModule } from 'app/shared/error-msg/error-msg.module';
import { EligibleDeclarantListComponent } from './eligible-declarant-list/eligible-declarant-list.component';
import { EligibleDeclarantCreateComponent } from './eligible-declarant-create/eligible-declarant-create.component';
import { EligibleDeclarantUpdateComponent } from './eligible-declarant-update/eligible-declarant-update.component';
import { CreateDeclarantComponent } from './create-declarant/create-declarant.component';
import { CreateDeclarantFromPersonComponent } from './create-declarant-from-person/create-declarant-from-person.component';
import { HRDeclarantReportComponent } from './hrdeclarant-report/hrdeclarant-report.component';
import { HRDeclarationReportComponent } from 'app/core/modules/declaration/hrdeclaration-report/hrdeclaration-report.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
@NgModule({
  declarations: [
    DeclarantComponent,
    DeclarantListComponent,
    UpdateDeclarantComponent,
    SearchDeclarantComponent,
    ConfirmationCreationDeclarantComponent,
    ConfirmationUpdateDeclarantComponent,
    ViewCreatedDeclarantComponent,
    ViewUpdatedDeclarantComponent,
    UploadListComponent,
    EligibleDeclarantListComponent,
    EligibleDeclarantCreateComponent,
    EligibleDeclarantUpdateComponent,
    CreateDeclarantComponent,
    CreateDeclarantFromPersonComponent,
    HRDeclarantReportComponent,
    HRDeclarationReportComponent
  ],
  imports: [
    CommonModule,
    DeclarantRoutingModule,
    FormsModule,
    HrRoutingModule,
    ReactiveFormsModule,
    ErrorMsgModule,
    NgbDatepickerModule,
    NgbTooltipModule,
    AngularMultiSelectModule
  ]
})
export class DeclarantModule { }
