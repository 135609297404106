export class AppSection {
    static readonly ADMIN = new AppSection('ADMIN', 'admin', 'admin');
    static readonly CRV = new AppSection('CRV', 'crv', 'crv');
    static readonly RH = new AppSection('RH', 'rh', 'rh');
    static readonly DECLARANTE = new AppSection('DECLARANTE', 'declarante', 'declarante');
    private constructor(private readonly key: string, public readonly code: string, public readonly urlSegment: string){}

    toString() {
        return this.key;
    }
}
