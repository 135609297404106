import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ClientService} from './client.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Users} from '../../core/models/users.model';
import {JwtHelperService} from '@auth0/angular-jwt';
import {BehaviorSubject, Observable} from 'rxjs';
import Swal from 'sweetalert2';
import {Transaction} from 'app/core/models/Transaction.model';
import {environment} from 'environments/environment';
import {UserModel} from 'app/core/models/user.model';
import {UserService} from 'app/core/services/users.service';
import { Section } from 'app/core/models/section.model';
import { AppSection } from 'app/core/models/app-section.model';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  private readonly temporaryCodeUrl = 'temporarycode';
  private readonly personContext = 'person';
  private readonly userContext = 'users';
  private url;

  /* private userData: any = {
    userId: "",
    userFullName: "",
    accessProfileCode: ""
  } */

  private _isLoogedIn$ = new BehaviorSubject<boolean>(false);
  is_loggedIn$ = this._isLoogedIn$.asObservable();
  user: UserModel | null;
  transactions: Array<Transaction>;
  public userAppSction: { code: string, urlSegment: string};
  firstTimeFetchingTransactions=true;
  private API_AUTH = `${environment.EDB_API}/authws/`;
  loginResponse: any;

  cameFromPasswordChanging: Boolean;
  constructor(private http: HttpClient,
    private client: ClientService,
    private jwt: JwtHelperService,
    private router: Router,
    private userService: UserService,
    private clientService: ClientService,
    private toastrService: ToastrService) {
    this._isLoogedIn$.next(!!this.client.getAccessToken());
    this.user = this.getUser(this.token);
    this.userAppSction = { code: '', urlSegment: ''};
    if(this?.user?.username?.length > 0)
      this.getTransactionsOnStart();
  }

  ngOnDestroy(): void {
    this.logout();
  }

  get token(): any {
    return this.client.getAccessToken();
  }

  public login(){
    this.firstTimeFetchingTransactions=true;
    const decodedToken = this.jwt.decodeToken(this.loginResponse.access_token);
    this.goToUserHomePage();
  }

  public attemptLogin(user: Users) {
    const payload = new HttpParams()
      .set('username', user.username)
      .set('password', user.password)
      .set('grant_type', 'password').toString();
    this.http.post<any>(this.client.urlAuthWS(`oauth/token`), payload).subscribe(
      (response) => {
        this.loginResponse = response;
        this.client.setAccessToken(response.access_token);
        this._isLoogedIn$.next(true);
        this.user = this.getUser(response.access_token)
        if(this.cameFromPasswordChanging){
          this.login()
        }else{
          this.url = this.clientService.urlAuthWS(`users/checkIfUserNeedsToChangePassword?nuit=${response.username}`)
          this.http.get<boolean>(this.url).subscribe(
            (changePassword) => {
              if(changePassword){
                this.user.userNeedsToChangePassword = true;
                this.router.navigate(['/administration/mudar-senha']);
              }else{
                this.user.userNeedsToChangePassword = false;
                this.login();
              }
            },
            (error) => {
              if(error.status == 400 || error.status == 401) {
                Swal.fire({
                  icon: 'error',
                  title: 'O sistema não conseguiu verificar se a sua senha já foi actualizada',
                  // text: "Ocorreu um erro, por favor volte a tentar!",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-danger'
                  }
                }).then(() => {
                  this.router.navigate(['/pages/login'])
                })
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Erro de conexão',
                  // text: "Ocorreu um erro, por favor volte a tentar!",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: 'btn btn-danger'
                  }
                }).then(() => {
                  this.router.navigate(['/pages/login'])
                })
              }
            }
          );
        }
      },
      (error) => {
        if(error.status == 400 || error.status == 401) {
          Swal.fire({
            icon: 'error',
            title: 'Senha ou Utilizador Inválido',
            // text: "Ocorreu um erro, por favor volte a tentar!",
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-danger'
            }
          }).then(() => {
            this.router.navigate(['/pages/login'])
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro de conexão',
            // text: "Ocorreu um erro, por favor volte a tentar!",
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-danger'
            }
          }).then(() => {
            this.router.navigate(['/pages/login'])
          })
        }

      });
  }


  public attemptLoginPublic(user: Users) {

    const payload = new HttpParams()
      .set('username', user.username)
      .set('password', user.password)
      .set('grant_type', 'password').toString();
    this.http.post<any>(this.client.urlAuthWS(`oauth/token`), payload).subscribe(
      (response) => {
        this.loginResponse = response;
        this.client.setAccessToken(response.access_token);
        this._isLoogedIn$.next(true);
        this.user = this.getUser(response.access_token)

        const decodedToken = this.jwt.decodeToken(this.loginResponse.access_token);


      },
    );
  }


  private getUser(token: string): UserModel | null {
    if (!token) {
      return null;
    }

    // const user = JSON.parse(atob(token.split('.')[1])) as UserModel;
    let user = this.jwt.decodeToken(token) as UserModel;
    // const username = user.username;
    user.accessProfileDescriptions = this.getAccessProfileDescriptions(user.accessProfileCodes);
    return user;
  }

  async getTransactionsOnStart() {
    if (this.token && this.firstTimeFetchingTransactions) {
        await this.getTransactions().then(data => {
          this.transactions = data;
          this.transactions = this.transactions.sort((a, b) => (a.position > b.position) ? 1 : -1);
          this.firstTimeFetchingTransactions=false;
        })
    }
    return this.transactions;
  }

  public async getTransactions() {
    return await new Promise<Array<Transaction>>((resolve, reject) => {
      this.http.get<any>(`${this.client.urlAuthWS('users/transactions')}`)
        .toPromise()
        .then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        )
    });
  }


  // The following Methods are used to change password

  checkIfPhoneMatchesNUIT(phone: number, nuit: number): Observable<JSON> {
    this.url = this.clientService.urlIPWS(`${this.personContext}/checkifphonematchesnuit?nuit=${nuit}&phone=${phone}`);
    return this.http.get<JSON>(this.url);

    //const a = this.http.get<JSON>('http://172.31.4.114:8082/authws/person/checkifphonematchesnuit?nuit=' + nuit + '&phone=' + phone);
    //const a = this.http.get<JSON>('http://localhost:8082/authws/person/checkifphonematchesnuit?nuit=' + nuit + '&phone=' + phone);

    // return this.http.get<JSON>(`${this.API_AUTH}person/checkifphonematchesnuit?nuit=${nuit}&phone=${phone}`);
  }

  getNewTemporaryCode(nuit: number, receiver: number): Observable<JSON> {
    this.url = this.clientService.urlAuthWS(`${this.temporaryCodeUrl}/create?nuit=${nuit}&receiver=${receiver}`);
    return this.http.post<JSON>(this.url, null);
    // return this.http.post<JSON>(`${this.API_AUTH}temporarycode/create?nuit=${nuit}&receiver=${receiver}`, null);
  }

  checkIfTemporaryCodeMatchesNUIT(temporayCode: number, nuit: number): Observable<JSON> {

    this.url = this.clientService.urlAuthWS(`${this.temporaryCodeUrl}/checkiftemporarycodematchesnuit?nuit=${nuit}&temporaryCode=${temporayCode}`)
    return this.http.get<JSON>(this.url);

    // return this.http.get<JSON>(`${this.API_AUTH}temporarycode/checkiftemporarycodematchesnuit?nuit=${nuit}&temporaryCode=${temporayCode}`);
  }

  deleteTemporaryCode(nuit: number): Observable<JSON> {
    this.url = this.clientService.urlAuthWS(`${this.temporaryCodeUrl}/delete?nuit=${nuit}`);
    return this.http.post<JSON>(this.url, null);
    // return this.http.post<JSON>(`${this.API_AUTH}temporarycode/delete?nuit=${nuit}`, null);
  }

  saveNewPassword(newPassword: string, nuit: number): Observable<JSON> {
    this.url = this.clientService.urlAuthWS(`${this.userContext}/updatePassword?nuit=${nuit}&newPassword=${newPassword}`);
    return this.http.post<JSON>(this.url, null);
    // return this.http.post<JSON>(`${this.API_AUTH}users/updatePassword?nuit=${nuit}&newPassword=${newPassword}`, null);
  }

  // The Methods above are used to change password

  public logout(): void {
      // window.sessionStorage.clear();
      this.firstTimeFetchingTransactions=true;
      this.client.resetAccessToken();
      this.transactions = null;
      this.router.navigate(['/pages/login']);

      this._isLoogedIn$ = new BehaviorSubject<boolean>(false);
      this.is_loggedIn$ = this._isLoogedIn$.asObservable();
      this.user = null;
      this.loginResponse = null;
  }

  public getAccessProfileDescriptions(accessProfileCodes: Array<String>) {

    let accessProfileDescriptionsResponse: Array<String> = [];

    for (let accessProfileCode of accessProfileCodes) {
      if(this.getAccessProfileDescription(accessProfileCode) != '') {
        accessProfileDescriptionsResponse.push(this.getAccessProfileDescription(accessProfileCode));
      }
    }

    return accessProfileDescriptionsResponse;
  }

  private getAccessProfileDescription(accessProfileCode) {

    switch (accessProfileCode) {
      case '0000':
        return 'Indefinido'
        break;

      case '00AD':
        return 'Administrador'
        break;

      case '00AS':
        return 'Administrador de Segurança'
        break;

      case '01CP':
        return 'Presidente da CRV'
        break;

      case '01CO':
        return 'Operador da CRV'
        break;

      case '01CV':
        return 'Verificador da CRV'
        break;

      case '01CS':
        return 'Supervisor da CRV'
        break;

      case '02HM':
        return 'Gestor de RH'
        break;

      case '02HO':
        return 'Operador RH'
        break;

      case '03DE':
        return 'Declarante'
        break;

      default:
        return ''
        break;
    }
  }

  private checkIfUserNeedsToChangePassword(username){
    this.url = this.clientService.urlAuthWS(`users/checkIfUserNeedsToChangePassword?nuit=${username}`)
        this.http.get<boolean>(this.url).subscribe(
          (changePassword) => {
            if(changePassword){
              this.user.userNeedsToChangePassword = true;
              this.router.navigate(['/administration/mudar-senha']);
            }else{
              this.user.userNeedsToChangePassword = false;
              this.login();
            }
          },
          (error) => {
            if(error.status == 400 || error.status == 401) {
              Swal.fire({
                icon: 'error',
                title: 'O sistema não conseguiu verificar se a sua senha já foi actualizada',
                // text: "Ocorreu um erro, por favor volte a tentar!",
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-danger'
                }
              }).then(() => {
                this.router.navigate(['/pages/login'])
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Erro de conexão',
                // text: "Ocorreu um erro, por favor volte a tentar!",
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn btn-danger'
                }
              }).then(() => {
                this.router.navigate(['/pages/login'])
              })
            }
          }
        );
  }

  public goToUserHomePage(){
    if (this.user.accessProfileCodes.includes("00AD") || this.user.accessProfileCodes.includes("00AS") ) {
      this.router.navigate(['/admin/inicio']);
    } else if ((this.user.accessProfileCodes.includes("02HM") && this.user.accessProfileCodes.includes("03DE")) || (this.user.accessProfileCodes.includes("02HO") && this.user.accessProfileCodes.includes("03DE"))) {
      this.router.navigate(['/rh/inicio']);
    } else if(this.user.accessProfileCodes.includes("02HM") || this.user.accessProfileCodes.includes("02HO")) {
      this.router.navigate(['/rh/inicio']);
    } else if (this.user.accessProfileCodes.includes("03DE")) {
      this.router.navigate(['/declarante/inicio']);
    } else if (this.user.accessProfileCodes.includes("01CP") || this.user.accessProfileCodes.includes("01CO") || this.user.accessProfileCodes.includes("01CV") || this.user.accessProfileCodes.includes("01CS")) {
       Swal.fire({
          icon: 'error',
          title: 'Erro de Login',
          text: "Não está autorizado a aceder as funcionalidades do seu perfil apartir deste ponto de acesso!",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger'
          }
        }).then(() => {
          if(environment?.EDB_API === "https://edbtreino-api.pgr.gov.mz"){
            window.location.href = 'https://edbtreinoprivado.pgr.gov.mz'  
          } else {
            this.router.navigate(['/pages/login'])
          }
        })                    
    } else if (this.user.accessProfileCodes.includes("ACOP01")) {
      Swal.fire({
        icon: 'error',
        title: 'Erro de Login',
        text: "Não está autorizado(a) a aceder as funcionalidades do seu perfil a partir deste ponto de acesso!",
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-danger'
        }
      }).then(() => {
        if(environment?.EDB_API === "https://edb-api.pgr.gov.mz"){
          window.location.href = 'https://mrip-treino.pgr.gov.mz'
        } else if(environment?.EDB_API === "https://edbtreino-api.pgr.gov.mz"){
          window.location.href = 'https://mrip-treino.pgr.gov.mz'
        }
      })
    } else {
      this.router.navigate(['/pages/404']);
    }
  }
}

if(environment?.EDB_API === ""){

}