import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientService } from 'app/security/services/client.service';
import { environment } from 'environments/environment';
import { take } from 'rxjs/operators';
import { Dgi } from '../models/dgi.model';

@Injectable({
  providedIn: 'root'
})
export class ETributacaoService {

  private API_INFOPROVIDER = `${environment.EDB_API}/infoprovws/person/`;
  private readonly dgiContext = 'dgi';
  private url;

  constructor(private http: HttpClient, private clientService: ClientService) { }


  /* public  searchForNuit(personNuit) {
    // return this.http.get(this.API_INFOPROVIDER+"etributacao/getTaxPayerPersonalDetails?nuit="+personNuit).pipe(take(1));
    return this.http.get(this.API_INFOPROVIDER + 'getNuitOwnerByNuit/' + personNuit).pipe(take(1));

  } */
  
  public  getByNuitDgi(personNuit) {
    this.url = this.clientService.urlDgi(`${this.dgiContext}/singleperson/${personNuit}`);
    return this.http.get<Dgi>(this.url);

  }
}
