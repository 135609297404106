import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ClientService} from 'app/security/services/client.service';
import {take} from 'rxjs/operators'
import {Observable} from 'rxjs';
import {Person} from '../models/person.model';

@Injectable({
  providedIn: 'root'
})
export class PersonService {
  private readonly personContext = 'person';
  private url;

  constructor(private http: HttpClient,
              private clientService: ClientService) {
  }

  public getPersonDetails(username) {
    this.url = this.clientService.urlIPWS(`${this.personContext}/details`);
    return this.http.get(this.url).pipe(take(1));
  }

  public createPerson(person: Person): Observable<Person> {
    this.url = this.clientService.urlIPWS(`${this.personContext}create/`)
    return this.http.post<Person>(this.url, person);
  }

  public searchPersonNUIT(nuit) {
    this.url = this.clientService.urlIPWS(`person/findPerson/` + nuit);
    return this.http.get<Person>(this.url);
  }

  public findAll() {
    this.url = this.clientService.urlIPWS(`${this.personContext}/all`);
    return this.http.get<Person[]>(this.url);
  }


}
