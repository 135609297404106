import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppSection } from 'app/core/models/app-section.model';
import { Observable } from 'rxjs';
import { SecurityService } from '../services/security.service';

@Injectable({
  providedIn: 'root'
})
export class RouterInterceptorGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router, private location : Location, private securityService : SecurityService){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.setSectionBasedOnCurrentUrl();
      
      
    return true;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.setSectionBasedOnCurrentUrl();
    return true;
  }

  public setSectionBasedOnCurrentUrl() {
    this.router.events.subscribe( event => {

      let urlPathString = this.location.path();
      let urlPathArray = urlPathString.split("/");

      if(urlPathArray[0] == '') {
        urlPathArray.splice(0,1);        
      }

      if(urlPathArray[0] == AppSection.ADMIN.code) {
        this.securityService.userAppSction.code = AppSection.ADMIN.code;
        this.securityService.userAppSction.urlSegment = AppSection.ADMIN.urlSegment;
      } else if(urlPathArray[0] == AppSection.CRV.code) {
        this.securityService.userAppSction.code = AppSection.CRV.code;
        this.securityService.userAppSction.urlSegment = AppSection.CRV.urlSegment;
      } else if(urlPathArray[0] == AppSection.RH.code) {
        this.securityService.userAppSction.code = AppSection.RH.code;
        this.securityService.userAppSction.urlSegment = AppSection.RH.urlSegment;
      } else if(urlPathArray[0] == AppSection.DECLARANTE.code) {
        this.securityService.userAppSction.code = AppSection.DECLARANTE.code;
        this.securityService.userAppSction.urlSegment = AppSection.DECLARANTE.urlSegment;
      }
      
      

      
    })
    
  }
  
}
