import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {HttpModule} from '@angular/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {AppComponent} from './app.component';
import {CommonModule} from '@angular/common';
import {SidebarModule} from './sidebar/sidebar.module';
import {FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';
import {FooterModule} from './shared/footer/footer.module';
import {NavbarModule} from './shared/navbar/navbar.module';
import {AuthLayoutComponent} from './layouts/auth/auth-layout.component';
import {AppRoutes} from './app.routing';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorHandlerInterceptor} from './security/interceptors/error-handler.interceptor';
import {ToastrModule} from 'ngx-toastr';
import {AuthenticationInterceptor} from './security/interceptors/oauth-token.interceptor';
import {AuthGuard} from './security/guards/auth-guard.service';
import {JwtModule} from '@auth0/angular-jwt';
import {SecurityModule, tokenGetter} from './security/security.module';
import {HrLayoutComponent} from './layouts/hr/hr-layout.component';
import {DeclarantLayoutComponent} from './layouts/declarant/declarant-layout.component';
import {DeclarantModule} from './core/modules/declarant/declarant.module';
import {SpinnerModule} from './shared/spinner/spinner.module';
import {SpinnerInterceptor} from './security/interceptors/spinner.interceptor';

import {AdminLayoutComponent} from './layouts/admin/admin-layout.component';
import {ManagmentLayoutComponent} from './layouts/managment-layout/managment-layout.component';
import {NgxSpinnerModule} from 'ngx-bootstrap-spinner';
// @ts-ignore
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {StoreModule} from '@ngrx/store';
import {TableReducer} from './shared/implementations/state/reducers/table.reducer';
import {HrModule} from './core/views/hr/hr.module';
import { SearchForDeclarationComponent } from './core/modules/public-powwow/search-for-declaration/search-for-declaration.component';
import { HttpLoaderInterceptorService } from './security/interceptors/http-loader-interceptor.service';
import { ViewAndAcceptRequestsComponent } from './core/modules/public-powwow/view-and-accept-requests/view-and-accept-requests.component';
import { DetailedRequestViewComponent } from './core/modules/public-powwow/detailed-request-view/detailed-request-view.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    DeclarantModule,
    HrModule,
    SecurityModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true
    }),
    NgbModule,
    HttpModule,
    StoreModule.forRoot({
      incomingObject$: TableReducer
    }),
    SpinnerModule,
    SidebarModule,
    NgxSpinnerModule,
    NavbarModule,
    FooterModule,
    FixedPluginModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    })
  ],
  providers: [
    // Comentando/Eliminando o AuthenticationInterceptor abaixo, o problema de recuperação de password desaparece, mas isso cria outros problemas. o melhor é que apenas recuperação de password não precise de AuthenticationInterceptor
    {provide: HTTP_INTERCEPTORS, useClass: HttpLoaderInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true},
    

    AuthGuard
  ],
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    HrLayoutComponent,
    DeclarantLayoutComponent,
    AdminLayoutComponent,
    ManagmentLayoutComponent,
    SearchForDeclarationComponent, 
    ViewAndAcceptRequestsComponent, DetailedRequestViewComponent,
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
