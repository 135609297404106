import { Component, OnInit } from '@angular/core';
import { EligibleDeclarantObject } from '../models/EligibleDeclarantObject';
import { AppState, GenericComponent } from '../../../../shared/implementations/GenericComponent';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { UploadlistService } from '../../../services/uploadlist.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckAssignedValue } from 'app/shared/custom-validator/check-assigned-value';
import { FormValidations } from 'app/shared/fom-validations/form-validations';

/**
 * @author Ivan Lifaniça
 */

@Component({
  selector: 'app-eligible-declarant-create',
  templateUrl: './eligible-declarant-create.component.html',
  styleUrls: ['./eligible-declarant-create.component.css']
})

export class EligibleDeclarantCreateComponent extends GenericComponent implements OnInit {

  // declarant: EligibleDeclarantObject;
  private eligDeclarant;
  toggleButton: boolean = false;
  toggleSubmitButton: string = 'Confirmar';

  form!: FormGroup;
  private formTitle = 'Cadastro Funcionário Elegível';
  private isDisabledForm: boolean = false;
  private genders = [{ id: 1, code: "M", name: "Masculino" }, { id: 2, code: "F", name: "Feminino" }];




  constructor(private uploadService: UploadlistService,
    private router: Router,
    location: Location,
    store: Store<AppState>,
    private formBuilder: FormBuilder) {
    super(location, store)
  }

  ngOnInit(): void {
    // this.declarant = new EligibleDeclarantObject();

    this.createForm();

    this.eligDeclarant = history.state.declarant;

    if (!CheckAssignedValue.isEmpty(this.eligDeclarant)) {
      this.toggleSubmitButton = "Actualizar";
      this.setEligibleDeclarantData(this.eligDeclarant);
    }


  }


  private createForm() {
    this.form = this.formBuilder.group({
      id: [null],
      nuit: [null, [Validators.required, FormValidations.nuit]],
      name: [null, [Validators.required]],
      surname: [null, [Validators.required]],
      gender: [null, [Validators.required]],
      email: [null, [Validators.email]],
      personalContact: [null, [Validators.required, FormValidations.phone]],
      alternativeContact: [null, [FormValidations.phone]],
      role: [null, [Validators.required]],
      roleDescription: [null, [Validators.required]],
      nominationDate: [null],
      startDate: [null, [Validators.required]],
      situation: [null, Validators.required]
    });
    
    this.form.controls.situation.valueChanges.subscribe(value =>{
      value == "Nomeado" ? this.form.controls.nominationDate.setValidators([Validators.required]) : this.form.controls.nominationDate.setValidators(null)
      this.form.controls.nominationDate.updateValueAndValidity();
    });
  }

  private setEligibleDeclarantData(eligibleDeclarant) {
    this.form.patchValue({
      id: eligibleDeclarant?.id,
      nuit: eligibleDeclarant?.nuit,
      name: eligibleDeclarant?.name,
      surname: eligibleDeclarant?.surname,
      gender: eligibleDeclarant?.gender,
      email: eligibleDeclarant?.email,
      personalContact: eligibleDeclarant?.personalContact,
      alternativeContact: eligibleDeclarant?.alternativeContact,
      unitCode: eligibleDeclarant?.unitCode,
      role: eligibleDeclarant?.role,
      roleDescription: eligibleDeclarant?.roleDescription,
      nominationDate: eligibleDeclarant?.nominationDate ? this.reformatDate(eligibleDeclarant?.nominationDate) : null,
      startDate: eligibleDeclarant?.startDate ? this.reformatDate(eligibleDeclarant?.startDate) : null,
      situation: eligibleDeclarant?.situation 
    })
  }

  cancel() {
    this.previousPage()
  }

  onConclude() {

    if (!this.form.valid) {

      Swal.fire({
        icon: "error",
        title: "Erro no preenchimento do Formulário!",
        text: "Por favor preencha devidamente o formulário e volte a tentar!",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-danger"
        }
      })

      this.checkFormValidation(this.form);

      return;
    }

    this.isDisabledForm = true;
    this.changeFormTitle();
  }

  onUpdate() {
    this.isDisabledForm = false;
    this.changeFormTitle();
  }

  onSubmit() {
    let formValue = this.getFormValueToSubmit();
    this.submit(formValue);
  }

  submit(formValue) {

    this.uploadService.saveEligibleDeclarant(formValue)
      .subscribe(
        (response) => {
          let swalTextSuccess = "Funcionário registado com sucesso!";

          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: swalTextSuccess,
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary",
            }
          }).then(() => {
            this.router.navigate(['/rh/declarante/listEligibleDeclarants'])
          })
        },
        (error) => {
          let errorMsg = "Ocorreu um erro, por favor volte a tentar mais tarde!";

          if (error?.error?.message) {
            errorMsg = error?.error?.message;
          } else if (error?.error?.prettyMessage) {
            errorMsg = error?.error?.prettyMessage
          }

          Swal.fire({
            icon: "error",
            title: "Erro na submissão",
            text: errorMsg,
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-danger"
            }
          })
        })
  }


  private getFormValueToSubmit() {
    let formValue = this.form.value;
    let nominationDate = formValue.nominationDate;
    let startDate = formValue.startDate;

    if (!CheckAssignedValue.isEmpty(nominationDate) && typeof (nominationDate) === 'object') {
      formValue.nominationDate = this.formatDate(nominationDate)
    }

    if (!CheckAssignedValue.isEmpty(startDate) && typeof (startDate) === 'object') {
      formValue.startDate = this.formatDate(startDate)
    }


    return formValue;
  }

  private changeFormTitle() {
    if (this.isDisabledForm) {
      this.formTitle = 'Confirmação de dados';
    } else {
      if(!CheckAssignedValue.isEmpty(this.eligDeclarant.id)) {
        this.formTitle = 'Actualização Funcionário Elegível';
      }
      this.formTitle = 'Cadastro Funcionário Elegível';
    }
  }

}
