import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DeclarantService} from '../../../services/declarant.service';

@Component({
  selector: 'app-confirmation-update-declarant',
  templateUrl: './confirmation-update-declarant.component.html',
  styleUrls: ['./confirmation-update-declarant.component.css']
})
export class ConfirmationUpdateDeclarantComponent implements OnInit {

  declarant: any;
  loading = true;

  constructor(private router: Router, private declarantService: DeclarantService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.declarantService.getDeclarantsById(this.route.snapshot.params['id']).subscribe(
      data => {
        this.declarant = data
        setTimeout(() => {
          this.loading = false;
        }, 1000)
      });
  }

  confirmation() {
    this.router.navigate(['rh/inicio']);
  }

}
