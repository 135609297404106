import { Injectable } from '@angular/core';
import { ExcelEligibleDeclarantValidation } from 'app/core/models/excel-eligible-declarant-validation.model';
import { CheckAssignedValue } from 'app/shared/custom-validator/check-assigned-value';
import { EligibleDeclarant } from '../models/EligibleDeclarant';

@Injectable({
  providedIn: 'root'
})
export class EligibleUploadListCollectErrorsValidationService {

  eligibleDeclarants: EligibleDeclarant[] = [];
  private errorsExcelEligible: ExcelEligibleDeclarantValidation[] = [];

  constructor() { }

  public collectEligibleDeclarantErrorsValidations(eligibleDeclarants: EligibleDeclarant[], target: DataTransfer, eligibleDeclarantNuits: Array<String>): ExcelEligibleDeclarantValidation[] {

    this.errorsExcelEligible = [];

    const nuitRegex = /^\d{9}$/;
    const phoneRegex = /^[0-9]\d*$/;
    const positiveNumberRegex = /^[0-9]\d*$/;
    const genderRegex = /^M$|^F$/;
    const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;



    /* TARGET VALIDATION */
    if (CheckAssignedValue.isEmpty(target)) {
      // throw new Error('Cannot use multiple files');
      let errorExcelEligible = new ExcelEligibleDeclarantValidation;
      errorExcelEligible.error = 'Nenhum ficheiro selecionado!';
      errorExcelEligible.position = '';
      this.errorsExcelEligible.push(errorExcelEligible);
    } else if (target.files.length === 0) {
      let errorExcelEligible = new ExcelEligibleDeclarantValidation;
      errorExcelEligible.error = 'Nenhum ficheiro selecionado!';
      errorExcelEligible.position = '';
      this.errorsExcelEligible.push(errorExcelEligible);
    } else if (target.files.length > 1) {
      let errorExcelEligible = new ExcelEligibleDeclarantValidation;
      errorExcelEligible.error = 'Deve carregar apenas um ficheiro de cada vez!';
      errorExcelEligible.position = '';
      this.errorsExcelEligible.push(errorExcelEligible);
    }



    /* ELIGIBLE VALIDATION */
    if (eligibleDeclarants?.length == 0) {
      let errorExcelEligible = new ExcelEligibleDeclarantValidation;
      errorExcelEligible.error = 'O ficheiro não pode estar vazio';
      errorExcelEligible.position = '';
      this.errorsExcelEligible.push(errorExcelEligible);
    }

    eligibleDeclarants?.forEach(eligibleDeclarant => {

      /* NUIT */
      if (CheckAssignedValue.isEmpty(eligibleDeclarant?.nuit)) {
        let errorExcelEligible = new ExcelEligibleDeclarantValidation;
        errorExcelEligible.error = 'Nuit é obrigatório';
        errorExcelEligible.position = `Na linha com o número: ${eligibleDeclarant?.numero ? eligibleDeclarant?.numero : ''}`;
        this.errorsExcelEligible.push(errorExcelEligible);
      }
      if (!nuitRegex.test(eligibleDeclarant?.nuit)) {
        let errorExcelEligible = new ExcelEligibleDeclarantValidation;
        errorExcelEligible.error = 'Nuit deve conter 9 dígitos';
        errorExcelEligible.position = `Na linha com o número: ${eligibleDeclarant?.numero ? eligibleDeclarant?.numero : ''}`;
        this.errorsExcelEligible.push(errorExcelEligible);
      }

      /* NOME */
      if (CheckAssignedValue.isEmpty(eligibleDeclarant?.nome)) {
        let errorExcelEligible = new ExcelEligibleDeclarantValidation;
        errorExcelEligible.error = 'Nome é obrigatório';
        errorExcelEligible.position = `Na linha com o número: ${eligibleDeclarant?.numero ? eligibleDeclarant?.numero : ''}`;
        this.errorsExcelEligible.push(errorExcelEligible);
      }

      /* APELIDO */
      if (CheckAssignedValue.isEmpty(eligibleDeclarant?.apelido)) {
        let errorExcelEligible = new ExcelEligibleDeclarantValidation;
        errorExcelEligible.error = 'Apelido é obrigatório';
        errorExcelEligible.position = `Na linha com o número: ${eligibleDeclarant?.numero ? eligibleDeclarant?.numero : ''}`;
        this.errorsExcelEligible.push(errorExcelEligible);
      }

      /* GENERO */
      if (CheckAssignedValue.isEmpty(eligibleDeclarant?.genero)) {
        let errorExcelEligible = new ExcelEligibleDeclarantValidation;
        errorExcelEligible.error = 'Gênero é obrigatório';
        errorExcelEligible.position = `Na linha com o número: ${eligibleDeclarant?.numero ? eligibleDeclarant?.numero : ''}`;
        this.errorsExcelEligible.push(errorExcelEligible);
      }
      if (!genderRegex.test(eligibleDeclarant?.genero)) {
        let errorExcelEligible = new ExcelEligibleDeclarantValidation;
        errorExcelEligible.error = 'Gênero deve assumir o valor M ou F!';
        errorExcelEligible.position = `Na linha com o número: ${eligibleDeclarant?.numero ? eligibleDeclarant?.numero : ''}`;
        this.errorsExcelEligible.push(errorExcelEligible);
      }

      /* EMAIL */
      if (!CheckAssignedValue.isEmpty(eligibleDeclarant?.email) && !emailRegex.test(eligibleDeclarant?.email)) {
        let errorExcelEligible = new ExcelEligibleDeclarantValidation;
        errorExcelEligible.error = 'Email inválido';
        errorExcelEligible.position = `Na linha com o número: ${eligibleDeclarant?.numero}`;
        this.errorsExcelEligible.push(errorExcelEligible);
      }

      /* CONTACTO */
      if (CheckAssignedValue.isEmpty(eligibleDeclarant?.contacto)) {
        let errorExcelEligible = new ExcelEligibleDeclarantValidation;
        errorExcelEligible.error = 'Contacto é obrigatório';
        errorExcelEligible.position = `Na linha com o número: ${eligibleDeclarant?.numero ? eligibleDeclarant?.numero : ''}`;
        this.errorsExcelEligible.push(errorExcelEligible);
      }
      if (!phoneRegex.test(eligibleDeclarant?.contacto)) {
        let errorExcelEligible = new ExcelEligibleDeclarantValidation;
        errorExcelEligible.error = 'Contacto deve conter apenas números!';
        errorExcelEligible.position = `Na linha com o número: ${eligibleDeclarant?.numero ? eligibleDeclarant?.numero : ''}`;
        this.errorsExcelEligible.push(errorExcelEligible);
      }

      /* CONTACTO alternativo */
      if (!CheckAssignedValue.isEmpty(eligibleDeclarant?.contacto_alternativo) && !phoneRegex.test(eligibleDeclarant?.contacto_alternativo)) {
        let errorExcelEligible = new ExcelEligibleDeclarantValidation;
        errorExcelEligible.error = 'Contacto Alternativo deve conter apenas números!';
        errorExcelEligible.position = `Na linha com o número: ${eligibleDeclarant?.numero ? eligibleDeclarant?.numero : ''}`;
        this.errorsExcelEligible.push(errorExcelEligible);
      }

      /* FUNCAO */
      if (CheckAssignedValue.isEmpty(eligibleDeclarant?.descricao_funcao)) {
        let errorExcelEligible = new ExcelEligibleDeclarantValidation;
        errorExcelEligible.error = 'Descrição da Função é obrigatória';
        errorExcelEligible.position = `Na linha com o número: ${eligibleDeclarant?.numero ? eligibleDeclarant?.numero : ''}`;
        this.errorsExcelEligible.push(errorExcelEligible);
      }

      /* DATA INICIO FUNCOES */
      if (CheckAssignedValue.isEmpty(eligibleDeclarant?.data_inicio_funcao)) {
        let errorExcelEligible = new ExcelEligibleDeclarantValidation;
        errorExcelEligible.error = 'Data de inicio de funções é obrigatória';
        errorExcelEligible.position = `Na linha com o número: ${eligibleDeclarant?.numero ? eligibleDeclarant?.numero : ''}`;
        this.errorsExcelEligible.push(errorExcelEligible);
      }
      if (!dateRegex.test(eligibleDeclarant?.data_inicio_funcao)) {
        let errorExcelEligible = new ExcelEligibleDeclarantValidation;
        errorExcelEligible.error = 'Data de inicio deve obedecer o formato AAAA-MM-DD';
        errorExcelEligible.position = `Na linha com o número: ${eligibleDeclarant?.numero ? eligibleDeclarant?.numero : ''}`;
        this.errorsExcelEligible.push(errorExcelEligible);
      }

      /* CARREIRA */
      if (CheckAssignedValue.isEmpty(eligibleDeclarant?.carreira)) {
        let errorExcelEligible = new ExcelEligibleDeclarantValidation;
        errorExcelEligible.error = 'Carreira é obrigatória';
        errorExcelEligible.position = `Na linha com o número: ${eligibleDeclarant?.numero ? eligibleDeclarant?.numero : ''}`;
        this.errorsExcelEligible.push(errorExcelEligible);
      }

      /* SEQUENCIA */
      if (CheckAssignedValue.isEmpty(eligibleDeclarant?.numero)) {
        let errorExcelEligible = new ExcelEligibleDeclarantValidation;
        errorExcelEligible.error = 'É obrigatório informar a sequência númerica na coluna número para todos registos';
        errorExcelEligible.position = ``;
        this.errorsExcelEligible.unshift(errorExcelEligible);
      }
      if (!CheckAssignedValue.isEmpty(eligibleDeclarant?.numero) && !positiveNumberRegex.test(eligibleDeclarant?.numero)) {
        let errorExcelEligible = new ExcelEligibleDeclarantValidation;
        errorExcelEligible.error = 'O campo número deve ser um valor númerico inetiro positivo!';
        errorExcelEligible.position = ``;
        this.errorsExcelEligible.unshift(errorExcelEligible);
      }

    });

    this.colletcDuplicateNuitErros(eligibleDeclarantNuits)

    return this.errorsExcelEligible;


  }
  

  public colletcDuplicateNuitErros(array: Array<String>) {

    const toFindDuplicates = arry => arry.filter((item, index) => arry.indexOf(item) !== index)
    const duplicateElements = toFindDuplicates(array);

    if (duplicateElements.length != 0) {
      let repeatedElementsString = '';
      duplicateElements.forEach(element => {
        repeatedElementsString = repeatedElementsString + ' ' + element
      });

      let errorExcelEligible = new ExcelEligibleDeclarantValidation;
      errorExcelEligible.error = `O(s) NUIT(s):${repeatedElementsString} encontra(m)-se repetidos`;
      errorExcelEligible.position = ``;
      this.errorsExcelEligible.push(errorExcelEligible);
      

    }

  }

}
