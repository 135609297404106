import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HrRoutingModule} from './hr-routing.module';
import {HrComponent} from './hr.component';
import {HttpModule} from '@angular/http';
import {HomeHrComponent} from './home-hr/home-hr.component';
import {DeclarantModule} from '../../modules/declarant/declarant.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDatepickerModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    HrComponent,
    HomeHrComponent,
  ],
  imports: [
    CommonModule, FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HrRoutingModule,
    DeclarantModule,
    NgbDatepickerModule,
    NgbTooltipModule
  ]
})
export class HrModule {
}
