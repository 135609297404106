import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientService } from 'app/security/services/client.service';
import { Observable } from 'rxjs';
import {take} from 'rxjs/operators'
import { DeclarationFilters } from '../models/declaration-filters.model';
import { Declaration } from '../models/declaration.model';
import { IncompleteDeclarationResponse } from '../models/incomplete-declaration-response.model';
import { SecondPartRequest } from '../models/secondPartRequest.model';

@Injectable({
  providedIn: 'root'
})
export class DeclarationService {

  private readonly personContext = 'person/';
  private readonly declarationContext = 'declaration/';
  private readonly verificationContext = 'verification/';
  private readonly publicPowwowContext = 'public-powwow/';

  private url;


  public stepsForm = [{ position: 0, visibility: false }, { position: 1, visibility: false }, { position: 2, visibility: false }, { position: 3, visibility: false }, { position: 4, visibility: false }, { position: 5, visibility: false }, { position: 6, visibility: false }, { position: 7, visibility: false }, { position: 8, visibility: false }, { position: 9, visibility: false }, { position: 10, visibility: false }];
  public statusDeclarationMsg = "A declaração encontra-se no estado \"Fechado\", não podendo a mesma ser actualizada!";



  constructor(
    private http: HttpClient,
    private clientService: ClientService) {
  }

  getSteptsDeclarationForm(): Array<Object> {
    return this.stepsForm;
  }

  public getDeclarationById(id: number) {
    this.url = this.clientService.urlDeclWS(`${this.declarationContext}fetch/${id}`);
    return this.http.get<Declaration>(this.url).pipe(take(1));
  }

  public getAllRequests(): Observable<any> {
    this.url = this.clientService.urlDeclWS(`${this.publicPowwowContext}findAllPendingSecondDeclarationRequests`)
    return this.http.get<SecondPartRequest[]>(this.url);
  }
  
  public getDeclarationByDeclarantId(declarantId: number) {
    this.url = this.clientService.urlDeclWS(`${this.declarationContext}find-by-declarant-id/${declarantId}`);
    return this.http.get(this.url).pipe(take(1));
  }

  public getDeclarationsByDeclarantId(declarantId: number) {
    this.url = this.clientService.urlDeclWS(`${this.declarationContext}find-all-by-declarant-id/${declarantId}`);
    return this.http.get(this.url).pipe(take(1));
  }

  
  public getDeclarationByNuit(nuit : String) {
    this.url = this.clientService.urlDeclWS(`${this.declarationContext}find-all-by-nuit/${nuit}`);
    return this.http.get(this.url).pipe(take(1));
  }

  public  getIncompleteDeclarationByDeclarantId(declarantId) : Observable<IncompleteDeclarationResponse> {
    this.url = this.clientService.urlDeclWS(`${this.declarationContext}find-incomplete-by-declarant/${declarantId}`)
    return this.http.get<IncompleteDeclarationResponse>(this.url).pipe(take(1));
  }


  private create(declaration: any) {
    this.url = this.clientService.urlDeclWS(`${this.declarationContext}addNew`)
    return this.http.post(this.url, declaration).pipe(take(1));

  }

  public rejectSecondPartVisualization(nuit) {
    this.url = this.clientService.urlDeclWS(`${this.publicPowwowContext}rejectSecondPartVisualization/${nuit}`)
    return this.http.post(this.url, nuit).pipe(take(1));

  }

  public makeTheRequest(request) {
    this.url = this.clientService.urlDeclWS(`${this.publicPowwowContext}createSecondDeclarationPartRequest`)
    return this.http.post(this.url, request).pipe(take(1));
  }

  public  getSpecificRequest(nuit, declarationId)  {
    this.url = this.clientService.urlDeclWS(`${this.publicPowwowContext}findRequestByNuitAndDeclarationId/${nuit}/${declarationId}`)
    return this.http.get(this.url).pipe(take(1));
  }


  private update(declaration: any) {
    this.url = this.clientService.urlDeclWS(`${this.declarationContext}update`)
    return this.http.put(this.url, declaration).pipe(take(1));
  }

  saveDeclaration(declaration: any) {
    if(declaration.id != null && declaration.id != undefined && declaration.id != "") {
      if(typeof (declaration?.declarantId) == "number") {
        return this.update(declaration);
      }
    } else {
      return this.create(declaration);
    }

  }

  public  searchForNuit(personNuit){
    this.url = this.clientService.urlIPWS(`${this.personContext}getNuitOwnerByNuit/${personNuit}`);
    return this.http.get(this.url).pipe(take(1));
  }

  public searchDeclaration(declarationFilters: JSON): Observable<any>{
    this.url = this.clientService.urlDeclWS(`${this.declarationContext}getFilteredDeclarations`)
    let params = new HttpParams().set("params",JSON.stringify(declarationFilters));
    return this.http.get(this.url,{params});
  }

  public getSectorVerifierAgentsAssignedDeclarationsNumber(sector: string): Observable<any>{
    this.url = this.clientService.urlDeclWS(`${this.verificationContext}numeroDeDeclaracoesAtribuidasParaCadaAgenteVerificador?sector=${sector}`);
    return this.http.get(this.url);
  }

  public findCurrentVerifierAgentNumberOfAssignedDeclarations(declarationID: number): Observable<any>{
    this.url = this.clientService.urlDeclWS(`${this.verificationContext}buscarNumeroDeDeclaracoesPorVerificarDoAgenteVerificadorActual?declarationID=${declarationID}`);
    return this.http.get(this.url);
  }

  public assignDeclarationForVerification(declarantIDAndVerifierNUIT): Observable<any>{
    this.url = this.clientService.urlDeclWS(`${this.verificationContext}DistribuirDeclaracaoParaVerificacao`);
    return this.http.post(this.url, declarantIDAndVerifierNUIT);
  }

  public distributeMyAssignedDeclarationForVerification(data): Observable<any>{
    this.url = this.clientService.urlDeclWS(`${this.verificationContext}distributeMyAssignedDeclarationsForVerification`);
    return this.http.post(this.url, data);
  }
  
  public generateDeclarationReport(declarationFilters: JSON){
    this.url = this.clientService.urlDeclWS(`${this.declarationContext}generate-declaration-report`)
    let params = new HttpParams().set("params",JSON.stringify(declarationFilters));
    return this.http.get(this.url,{params, responseType: 'blob' as 'json'});
  }

  public searchForDeclarationFirstPart(nuit, names){
    this.url = this.clientService.urlDeclWS(`${this.declarationContext}find-declarantion-first-part/${nuit}/${names}`)                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
    return this.http.get(this.url).pipe(take(1));
  }

  public getNeededDeclarationTypeByDeclarantNUIT(nuit){
    this.url = this.clientService.urlDeclWS(`${this.declarationContext}${nuit}/get-needed-declaration-type`)
    return this.http.get(this.url).pipe(take(1));
  }

  getDeclarantLastSubmittedDeclaration(nuit: string){
    this.url = this.clientService.urlDeclWS(`${this.declarationContext}${nuit}/last`)
    return this.http.get(this.url).pipe(take(1));
  }

}