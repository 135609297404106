import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class HttpLoaderInterceptorService implements HttpInterceptor {
  
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const authReq = req.url.endsWith("find-declarantion-first-part") ? req.clone({
      headers: req.headers.set('Authorization', "No")
    }) : req;

    return next.handle(authReq).pipe();


  
  }

}