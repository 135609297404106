import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeclarationService } from 'app/core/services/declaration.service';
import { SecurityService } from 'app/security/services/security.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-view-and-accept-requests',
  templateUrl: './view-and-accept-requests.component.html',
  styleUrls: ['./view-and-accept-requests.component.css']
})
export class ViewAndAcceptRequestsComponent implements OnInit {

  seconPartRequests: any;
  declarant: any;
  declaration: any;
  foundRequests: boolean = false;
  tableVisibillity: boolean = true;
  detailedViewVisibillity = false;


  constructor(private router: Router, private declarationService: DeclarationService, private securityService: SecurityService) { }

  ngOnInit(): void {
    this.getAllRequests()
  }

  getAllRequests() {

    this.declarationService.getAllRequests()?.subscribe(
      response => {

        this.seconPartRequests = response;
        if(response==null){
          this.foundRequests = true;
        }

      },
      error => {
        const errorPrettyMsg = error.error?.message ? error.error?.message : "O sistema encontra-se indisponível, por favor volte a tentar!";
        Swal.fire({
          icon: "error",
          title: "Indisponibilidade para busca de Requisições para visualição da segunda parte",
          text: errorPrettyMsg,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-danger"
          }
        });
      }
    );



  }

  aplicationInConstruction(){
    Swal.fire({
      icon: "error",
      title: "Funcionalidade em Construção",
      text: "Volte a tentar mais tarde. Ass: Rafael MC Junior",
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-danger"
      }
    })

  }


  viewDetailedDeclaration(declarationId, nuit){
    this.router.navigate([`/${this.securityService.userAppSction.urlSegment}/public-powwow/detailed-request-view/${nuit}/another/${declarationId}`]);
  }
 

  // GET DECLARANT BY DECLARATION 



}
