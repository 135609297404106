import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor() {
  }

  public carrega(): string{
    return `${environment.EDB_API}`;
  }

  public url(context: string): string {
    return `${environment.EDB_API}${context}`;
  }

  public urlAuthWS(resource: string): string {
    return this.url(`/authws/${resource}`);
  }

  public urlTransactions(): string {
    return `${environment.EDB_API}/authws/users/transactions`;
  }

  public urlDeclWS(resource: string): string {
    return this.url(`/declarationws/${resource}`);
  }
  
  public urlIPWS(resource: string): string {
    return this.url(`/infoprovws/${resource}`);
  }

  public urlNWS(resource: string): string {
    return this.url(`/notifyws/${resource}`);
  }

  public urlDgi(resource: string) : string {
    return this.url(`/singlepersonws/${resource}`);
  }

  public clientId(): string {
    return `${environment.EDB_OAUTH_CLIENT}`;
  }

  public getAccessToken(): string {
    return sessionStorage.getItem('token');
  }

  public setAccessToken(token: any) {
    sessionStorage.setItem('token', token);
  }

  public resetAccessToken() {
    sessionStorage.clear();
  }

  public setUserData(userData) {
    sessionStorage.setItem('userData', JSON.stringify(userData));
  }

  public getUserData() {
    let userData = sessionStorage.getItem('userData');
    return JSON.parse(userData);
  }
}
