import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppState, GenericComponent } from '../../../../shared/implementations/GenericComponent';
import { Store } from '@ngrx/store';
import { EligibleDeclarantObject } from '../models/EligibleDeclarantObject';
import { PersonService } from '../../../services/person.service';
import { DeclarantService } from '../../../services/declarant.service';
import Swal from 'sweetalert2';
import { Person } from '../../../models/person.model';
import { DeclarantsRequest } from '../../../models/declarants-request.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThrowStmt } from '@angular/compiler';
import { CheckAssignedValue } from 'app/shared/custom-validator/check-assigned-value';


@Component({
  selector: 'app-view-created-declarant',
  templateUrl: './view-created-declarant.component.html',
  styleUrls: ['./view-created-declarant.component.css']
})
export class ViewCreatedDeclarantComponent extends GenericComponent implements OnInit {
  form!: FormGroup;
  eligDeclarant: EligibleDeclarantObject;
  persons: Person;
  toggleButton: boolean = false;
  toggleName: string = 'Confirmar dados do Funcionário Elegivel';
  toggleConfirmButton: string = 'Confirmar';
  startDate: {};
  nominationDate: {};
  declarant: DeclarantsRequest = new DeclarantsRequest();

  private isDataFormAvailable: Boolean = false;

  constructor(private router: Router,
    location: Location,
    private formBuilder: FormBuilder,
    private person: PersonService,
    private declarantService: DeclarantService,
    store: Store<AppState>) {
    super(location, store)
  }

  ngOnInit(): void {
    
    this.eligDeclarant = history.state.declarant;
    this.startDate = CheckAssignedValue.isEmpty(this.eligDeclarant?.startDate) ? null : this.reformatDate(this.eligDeclarant?.startDate);
    this.nominationDate = CheckAssignedValue.isEmpty(this.eligDeclarant?.nominationDate) ? null : this.reformatDate(this.eligDeclarant?.nominationDate);
    
    this.getPerson(this.eligDeclarant?.nuit);
    this.createForm();
  }

  private createForm() {
    this.form = this.formBuilder.group({
      declarant: this.declarantForm(),
    })
  }

  private declarantForm() {
    return this.formBuilder.group({
      person: this.personForm(),
      functionStartDate: [null, Validators.required],
      nominationDate: [null],
      role: [null, Validators.required],
      roleDescription: [null, Validators.required],
      institution: [null],
      sector: [null],
    })
  }

  private personForm() {
    return this.formBuilder.group({
      id: [null],
      nuit: [null, Validators.required],
      names: [null, Validators.required],
      surname: [null, Validators.required],
      gender: [null, Validators.required],
      maritalStatus: [null, Validators.required],
      nationality: [null, Validators.required],
      country: [null, Validators.required],
      province: [null, Validators.required],
      district: [null],
      neighborhood: [null],
      block: [null],
      roadOrAvenue: [null],
      houseNumber: [null],
      documentType: [null, Validators.required],
      documentId: [null, Validators.required],
      email: [null,[Validators.required]],
      primaryPhoneNo: [null, Validators.required],
      secondaryPhoneNo: [null],
    })
  }

  onDateSelect(event) {
    this.eligDeclarant.startDate = CheckAssignedValue.isEmpty(event) ? null : this.formatDate(event);
    this.eligDeclarant.nominationDate = CheckAssignedValue.isEmpty(event) ? null : this.formatDate(event);
  }

  cancel() {
    if (this.toggleButton) {
      this.toggleButton = false;
      this.toggleName = 'Confirmar dados Funcionário Elegível';
      this.toggleConfirmButton = 'Confirmar'
    } else {
      this.previousPage();
    }
  }

  reject() {
    this.declarantService.rejectDeclarant(this.eligDeclarant.nuit).subscribe((data) => {
    }, (error) => {
      Swal.fire(error.error.prettyMessage, error.error.prettyMessage, 'error')
    },
      () => {
        Swal.fire('Declarante rejeitado', 'Os dados do funcionário eligível foram rejeitados');
        this.router.navigate(['/rh/declarante/upload-list'])
      }
    );
  }

  async getPerson(nuit) {
    await this.person.searchPersonNUIT(nuit).subscribe(
      (response) => {
        if (response != null) {
          this.persons = response;
          this.setPersonData(this.persons);
          this.isDataFormAvailable = true;
        }
      },
      (error) => {
      },
      () => {
      });
  }

  private setPersonData(personData) {
    this.form.patchValue({
      declarant: {
        person: {
          id: personData?.id,
          nuit: personData?.nuit,
          names: personData?.names,
          surname: personData?.surname,
          gender: personData?.gender,
          maritalStatus: personData?.maritalStatus,
          nationality: personData?.nationality,
          country: personData?.country,
          province: personData?.province,
          district: personData?.district,
          neighborhood: personData?.neighborhood,
          block: personData?.block,
          roadOrAvenue: personData?.roadOrAvenue,
          houseNumber: personData?.houseNumber,
          documentType: personData?.documentType,
          documentId: personData?.documentId,
          email: this.eligDeclarant?.email,
          primaryPhoneNo: this.eligDeclarant?.personalContact,
          secondaryPhoneNo: this.eligDeclarant?.alternativeContact,
        },
        institution: personData?.institution[personData?.institution.length - 1],
        sector: personData?.sector[personData?.sector.length - 1],
        role: this.eligDeclarant?.role,
        roleDescription: this.eligDeclarant?.roleDescription,
        functionStartDate: CheckAssignedValue.isEmpty(this.eligDeclarant?.startDate) ? null : this.reformatDate(this.eligDeclarant?.startDate),
        nominationDate: CheckAssignedValue.isEmpty(this.eligDeclarant?.nominationDate) ? null : this.reformatDate(this.eligDeclarant?.nominationDate)
      }
    })
  }

  onSubmit() {
    let formValue = this.form.value;
    let newDate = new Date();
    
    if (formValue.declarant.nominationDate) {
      let nominationDate = formValue.declarant.nominationDate;
      let nominationDay = nominationDate.day ? String(nominationDate.day).padStart(2, '0') : newDate.getDay();
      let nominatioMonth = nominationDate.month ? String(nominationDate.month).padStart(2, '0') : newDate.getMonth()+1;
      let nominationYear = nominationDate.year ? nominationDate.year : newDate.getFullYear();
      formValue.declarant.nominationDate = `${nominationYear}-${nominatioMonth}-${nominationDay}`;
    } else {
      formValue.declarant.nominationDate = `${newDate.getFullYear()-newDate.getMonth()+1-newDate.getDay()}`;
    }

    if (formValue.declarant.functionStartDate) {
      let functionStartDate = formValue.declarant.functionStartDate;
      let functionStartDay = functionStartDate.day ? String(functionStartDate.day).padStart(2, '0') : newDate.getDay();
      let functionStartMonth = functionStartDate.month ? String(functionStartDate.month).padStart(2, '0') : newDate.getMonth()+1;
      let functionStartYear = functionStartDate.year ? functionStartDate.year : newDate.getFullYear();
      formValue.declarant.functionStartDate = `${functionStartYear}-${functionStartMonth}-${functionStartDay}`;
    } else {
      formValue.declarant.functionStartDate = `${newDate.getFullYear()-newDate.getMonth()+1-newDate.getDay()}`;
    }
    

    if (this.toggleButton == false) {
      this.toggleButton = true;
      this.toggleName = 'Confirmar dados do funcionário';
      this.toggleConfirmButton = 'Confirmar'
    } else {
      let swalSubmitTextSuccess = "Declarante registado com sucesso!";
      let swalUpdateTextSuccess = "Declarante actualizado com sucesso!";
      let swalTextSuccess = formValue?.id ? swalUpdateTextSuccess : swalSubmitTextSuccess;

      this.declarantService.createDeclarant(formValue).subscribe(       
        (response) => {
          if (CheckAssignedValue.isEmpty(formValue?.id)) {
            this.sendPasswordNotification(formValue?.declarant?.person?.nuit);
          } else {  
            Swal.fire({
              icon: "success",
              text: swalTextSuccess,
              confirmButtonText: 'Fechar',
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              }
            }).then((result) => {
              this.router.navigate(['admin/inicio']);
            })
          }

          Swal.fire('Declarante criado com sucesso', 'Declarante criado com sucesso', 'success')
          this.router.navigate(['/rh/declarante/upload-list'])
        },
        (error) => {
          let errorMsg = "Ocorreu um erro, por favor volte a tentar mais tarde!";
          if (error?.error?.message) {
            errorMsg = error?.error?.message;
          } else if (error?.error?.prettyMessage) {
            errorMsg = error?.error?.prettyMessage
          }

          Swal.fire({
            icon: "error",
            title: "Erro",
            text: errorMsg,
            confirmButtonText: 'Fechar',
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary"
            }
          })
        });
    }
  }

  private sendPasswordNotification(nuit: String) {
    let swalTextSuccess = "Notificação de credenciais enviada com sucesso!";
    let swalTexError = "O utilizador foi registado com sucesso. Mas não foi possível enviar as credenciais! Por favor tente enviar as credenciais mais tarde.";
    this.declarantService.SendCredentials(nuit).subscribe(
      (response) => {
        Swal.fire({
          icon: "success",
          title: "Envio de credenciais",
          text: swalTextSuccess,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary"
          }
        }).then(() => {
          this.router.navigate(['rh/declarante/listEligibleDeclarants']);
        })
      },
      (error) => {
        Swal.fire({
          icon: "warning",
          title: "Envio de credenciais",
          text: swalTexError,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary"
          }
        }).then(() => {
          this.router.navigate(['rh/declarante/listEligibleDeclarants']);
        })
      }
    )
  }
}
