import {Component, OnInit} from '@angular/core';
import {DeclarantService} from '../../../services/declarant.service';
import {Router} from '@angular/router';
import {SecurityService} from 'app/security/services/security.service';
import {Store} from '@ngrx/store';
import {AppState, GenericComponent} from '../../../../shared/implementations/GenericComponent';
import {Location} from '@angular/common';
import {Declarant} from '../../../models/declarant.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-declarant-list',
  templateUrl: './declarant-list.component.html',
  styleUrls: ['./declarant-list.component.css', '../../../../shared/implementations/generic.component.css']
})


export class DeclarantListComponent extends GenericComponent implements OnInit {
  public declarants: Declarant[];
  declarant: Declarant;

  constructor(private service: DeclarantService,
              private router: Router,
              private securityService: SecurityService,
              store: Store<AppState>,
              location: Location) {
    super(location, store);
  }

  ngOnInit(): void {
    this.declarants = [];

    this.dataTable = {
      headerRow: ['NOME', 'APELIDO', 'NUIT', 'CARGO', 'DATA DE NOMEAÇÃO', 'EMAIL', 'NUMERO DE TELEFONE PRINCIPAL', 'NUMERO DE TELEFONE ALTERNATIVO'],
      footerRow: ['NOME', 'APELIDO', 'NUIT', 'CARGO', 'DATA DE NOMEAÇÃO', 'EMAIL', 'NUMERO DE TELEFONE PRINCIPAL', 'NUMERO DE TELEFONE ALTERNATIVO']
    }

    this.service.listByInstitution().subscribe((declarante) => {
      if (declarante != null) {
        this.declarants = declarante;
      }
      setTimeout(() => {
        this.rendersTable()
      })
    }, () => {
    }, () => {
    })

    this.incomingObject$.subscribe(value => {
      this.declarant = JSON.parse(JSON.stringify(value.incomingObject))
    })
  }

  selectedDeclarant(declarants: Declarant) {

  }

  cancel() {
    this.router.navigate(['rh/inicio'])
  }

  removeDeclarant() {
    this.service.inactivateDeclarants(this.declarant.id).subscribe(declarant => {

      }, (error) => {
        Swal.fire('Declarante removido com sucesso', 'Declarante removido com sucesso', 'success')

      },
      () => {
        this.router.navigate(['/rh/declarante/declarant-list']);
        setTimeout(() => {
          Swal.fire('Declarante removido com sucesso', 'Declarante removido com sucesso', 'success')

        })
      })

  }


  deleteDeclarant() {
    let inactivate = (function (_ruleID: number) {
      return this.removeEarning()
    }).bind(this)

    this.showAlert(
      this.alertProps = {
        text: 'Pretende excluir este Declarante?',
        alertText: 'Confirmado.',
        callback: inactivate
      }
    )
  }


  update() {
     this.router.navigate(['/rh/declarante/update-declarant'], {state: {declarant: this.declarant}})
  }
}

